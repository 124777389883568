

.headerDropdown_container {
	position  : relative;
    margin-left: -5px;
	display: inline-block;
	width  : 100%;

	background-color: $white;
	@include box-shadow (0px 2px 5px 0px #cccccc);

	.headerDropdown_title {
		color: $grey;
		padding: $lh $lh $lh/2 $lh;
	}

	.headerDropdown_content {
		padding-top: $lh/2;
		padding-bottom: $lh*2;
		max-height: $lh*12;
		overflow-y: auto;
		font-size: $fs;
	}

    .headerNotificationDropdown_content {
		max-height: $lh*10.5;
		overflow-y: auto;
		font-size: $fs;
	}

	.headerDropdown_footer {
		z-index: 20;
		position: absolute;
		bottom: 0;
		left  : 0;
		right : 0;


		background-color: $white;
		text-align: center;
		&:hover {
			.headerDropdownFooter_label {
				background-color: $grey-light;
			}
		}
		.headerDropdownFooter_label{
			display: block;
			padding: $lh/2 0;
			font-size: $fs;
			color: $blue-dark;
		}
	}
}


.notificationList_container {
	background-color: $white;

	.notificationListLi {
		display: block;

		.notificationListLink {
			display: block;
			padding: $lh/2 $lh;

			&:hover {
				background-color: rgba($grey-light, 0.45);
			}

			.notificationListIcon {
				display: inline-block;
				padding-right: $lh/2;
				vertical-align: top;
				font-size: $lh;
				color: $blue;
				.fal {
                    display: block;
                    font-size: $lh;
                    color: $blue-light;

				}
			}
			.notificationListMessage_container {
				display: inline-block;
				font-size: $fs;
				.notificationListMessage_label {
					color: $black-dark;
					font-weight: 600;
				}
				.notificationListMessage_subtitle {
					color: $grey;
				}
			}
		}
	}
}

.userUseTouchScreenLayout a {
    padding-top: 10px;
}

.userChangePassword {
    color: $blue-dark;

    &:hover {
        color: $blue;
    }
}


// User Profile :: BEGIN
.userProfile_container {
	padding: $lh/2 $lh/2 $lh $lh;
	.userImage_wrapper {
		display: inline-block;
		padding-right: $lh;
		.userImage_container {
			display: inline-block;
			width : $lh+10;
			height: $lh*3;

			text-align: center;
			// vertical-align: middle;
			// @include border-radius(50%);
			// overflow: hidden;

			img {
				display: inline-block;
				width: 100%;
				height: auto;
				vertical-align: middle;
			}
			.fa {
				display: inline-block;
				vertical-align: middle;
				font-size: 30px;
				color: $grey;
			}
		}
	}

	.userData_container {
		display: inline-block;
		vertical-align: middle;
		.userDataName {
			line-height: $lh;
			font-weight: 500;
			color: black;
            min-width: 160px;
		}
		.userDataEmail {
			color: lighten($black, 20%);
		}
        .userLoginLocation {
			color: lighten($black, 20%);
		}
	}
}
.userProfileDropdownFooter {
	border-top: 1px solid $grey-light;

	.headerDropdownFooter_label {
		color: $blue-dark;
	}
	&:hover {
		background-color: $grey-light;
	}
}
// User Profile :: END
