app-groups-content {
    .groups-content {
        height: calc(100vh - 143px);
    }

    .tab-content {
        min-height: calc(100% - 45px);
        width: 100%;
        max-height: calc(100vh - 188px);
    }
}
