



// Kiosk Detail Configuration Accordion
.dynamic-accordion {

	.accordionItemHeader_container {
		@include clearfix;

		.accordionTitle_container {
			.accordionTitle {
				display: inline-block;
			}
			.errorIcon {
				display: inline-block;
				padding-left: $lh/4;
			}
		}

		.accordionHeaderControls_container {
			float: right;
            li {
                display: inline-block;
                padding-right: $basis;
                &:last-child {
                    padding-right: 0;
                }
            }
		}
	}// Accordion Item Header :: end



    .sortButtons_wrapper {
        padding-right: $lh/2;
        .sortIcon_container {
            padding-right: $lh/2;
            color: $grey-dark;
            &:last-child {
                padding-right: 0;
            }
            &:hover {
                color: $blue;
            }

        }
    }


    .cardContent_container {
		padding: $lh*1.3 $lh*1.5 !important;
	}
}

