

.emptyState {
    color: $empty-font;

    &.emptyState-large {
        min-width: ($dropdown-width*4)+($basis*4);
        .emptyMessage {
            padding: $basis;
        }
    }
    &.bordered {
        border: 1px solid $black-light;
    }

    .emptyMessage {
       padding: $basis/1.5;
        display: inline-block;
    }
}

.emptyState_wraper_aligh_left {
    text-align: left !important;
}

.emptyState_wrapper {
    text-align: center;
    user-select: none;
    .emptyState_container {
        display: inline-block;
        margin: 0 auto;

        color: $empty-font;
        text-align: center;
        overflow: hidden;
        h1,
        h3 {
            color: $empty-font;
        }
        .emptyStateImage {
            display: block;
            margin: 0 auto;
            margin-bottom: $lh*2;
            height: auto;
            width: 60%;
        }
    }
}
