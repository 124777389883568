app-cashout-entry {
    .page-wrapper {
        position: initial;
        min-height: calc(100vh - 50px );
    }
}
app-cashout-entry-content {
    .page-content {
        padding: 16px;
        margin: auto;
        .page-content-title {
            margin-bottom: 0;
        }
    }
    .cashout-entry-scroll {
        max-height: 90rem;
        overflow-y: scroll;
    }
    .page-content-header-wrapper {
        justify-content: space-between;
        align-items: center;
        margin-top: -16px;
        height: 75px;
        app-button {
            .button  {
                width: initial;
                padding: 8px 26px;
                font-size: 14px;
                line-height: 1;
                background-color: $white;
                border: 1px solid $grey-dark;
                color: $black-dark ;
                height: 32px;
                &:disabled, &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                    color: inherit;
                }
            }
        }
    }
}
app-cashout-entry-filter {
    display: block;
    background-color: #D0DCEF;
    padding: 16px;
    margin: 0 -16px;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);

    .dropdown_container {
        max-width: 260px;
    }
    .picker_container {
        width: 260px;
    }
}
app-abstract-dialog-container {
    .form-row {
        display: flex;
        align-items: center;
        min-height: 32px;
        margin-bottom: 8px;
        label {
            flex-basis: 141px;
            padding-right: 16px;
            white-space: pre-wrap;
        }
        app-dropdown, input, p, app-checkbox  {
            flex-basis: calc(100% - 141px);
            max-width: 276px;
        }
    }
    .textarea-row {
        padding-top: 52px;
        textarea {
            width: 100%;
            min-height: 90px;
        }
    }
    .app-enter-submission-amounts {
        min-width: 450px;
        min-height: 250px;
        h1 {
            margin-bottom: 21px;
        }
        .dialog-action-buttons-wrapper {
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: 0 ;
            padding: 16px 0 0 0;
            margin-top: 8px;
            background: $white;
            button {
                width: 136px;
                font-size: 16px;
                padding: 9px 0;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
.cashout-entry-action-buttons-wrapper {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0 ;
    padding: 16px 0;
    margin-top: 8px;
    background: $white;
    button {
        width: 150px;
        font-size: 16px;
        padding: 9px 0;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
    &.disabled {
        opacity: 1;
        button {
            opacity: 0.5;
        }
    }
}
.no-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 0 ;

    svg {
        margin-bottom: 20px;
    }
    h1 {
        color: #7B88A3;
    }
    h2 {
        font-size: 16px;
        color: rgba(123, 136, 163, 0.5)
    }
}

app-cashout-entry-content {
    .remove {
        display: none!important;
    }
}
.with-content {
    .payment-summary-table {
        margin: 0 0;
        padding: 20px 0 0 0;
    }
    .page-header{
        padding-bottom: 20px;
    }
    .orderReportTable_container .table_wrapper {
        padding: 0 0 28px 0;
    }
}
.bold-text {
    font-weight: 700;
}
app-add-new-entry-dialog-content {
    .form-row-wrapper {
        min-height: 138px;
    }
}
