.simple-table {
    &.table-nested {
        margin-top: $lh/2;
        margin-left: $lh*2;
    }
    thead,
    tbody,
    tfoot {
        border: 0;
    }
    tr {
        &:hover {
            color: initial;
        }
    }
    th, td {
        border: 0;
        padding: 0 $lh/2;
        white-space: nowrap;
        vertical-align: top;

        &:first-child {
            padding-left: 0
        }
        &:last-child {
            padding-right: 0;
        }
    }

    .th-small {
        font-size: 10px;
    }

    .td-small {
        font-size: 12px;
    }

    .initial-white-space {
        white-space: initial;
    }
}