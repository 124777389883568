input:focus,
textarea:focus,
select:focus {
    outline-offset: 0;
}

// Input reset :: BEGIN
input, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'],
[type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'],
[type='time'], [type='url'], textarea {
	box-sizing: border-box;
	margin : 0;
	padding: $basis/3 $basis/1.5; // 4px 8px
	border : 1px solid $black-light;
	@include border-radius(0);

    outline: none;
	width : $basis*12.5; //150px;
	height: auto;
    overflow: hidden;
    box-shadow: none;
    background-color: #fff;

    line-height: $lh;
    font-size: $fs;
    font-weight: 500;
    font-family: $ff_sans-pro;
	color: $black-dark;

    &:focus {
        margin : 0;
        padding: $basis/3 $basis/1.5; // 4px 8px
        border : 1px solid $black-dark;


        outline: none;
        box-shadow: none;
        background-color: $white;


        line-height: $lh;
        font-size  : $fs;
        font-weight: 500;
        font-family: $ff_sans-pro;

        @include transition(none);
        @include appearance(none);
    }
}

input[type='color'] {
    height : 30px;
    width  : 100%;
    min-width : 44px;
    max-width : 150px;
}


// Disabled
input:disabled, input.disabled, input[readonly],
textarea:disabled, textarea.disabled, textarea[readonly] {
    background-color: $grey;
    cursor: not-allowed;
}
input:disabled, input[readonly],
textarea:disabled, textarea[readonly] {
  background-color: $white;
  cursor: auto;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
    &:focus, &:active {
        -moz-appearance:textfield;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
}

// Input reset :: END


.input-large {
    width: 100%;
    min-width: $basis*17; // 204px
}

.input-xs {
    width: 100%;
    text-align: center;
    max-width: $lh*2;
    padding: $lh/5 0;
    &:focus {
        padding: $lh/5 0;
    }
}

// login inputs
.input-tall {
    //height: 36px
    padding-top   : calc(#{$basis/3} + 3px);
    padding-bottom: calc(#{$basis/3} + 3px);
    &:focus {
        padding-top   : calc(#{$basis/3} + 3px);
        padding-bottom: calc(#{$basis/3} + 3px);
    }
}

.no-borders-input {
    border : 0;
    padding: 0;
    &:focus {
        border : 0;
        padding: 0;
    }
}

.inputLabelGroup {
    .label {
        color: $grey-dark;
        line-height: 1.25;
    }
    .labelActive {
        color: $blue;
    }
    .message  {
        font-size  : $fs-small;
        line-height: 1.25;
        color: $grey-dark;
        white-space: normal;
        &.error-message {
            color: $red;
        }
    }
}

// Text area
.no-resize {
    resize : none;
    overflow: auto;
}

.input-money {
    position: relative;
    &:before, &::before {
        content: "$";
        position: absolute;
        left: 9px;
        top: 8px;
    }

    input.form-input {
        padding-left: 16px;
        width: 100%;

        &:focus {
            margin: inherit;
            padding-left: 16px;
        }
    }
}
.order-reports-page .pageContent_wrapper .mainContent_wrapper .row-padding{
    // TODO create a horizontal and vertical class and then apply them to different areas that
    //      need to have custom styling options
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.35);
    }
}
app-input-number-step {
    display: flex;
    min-width: 112px;
    justify-content: space-between;
    align-items: center;
    .purchase-form-counts-input {
        width: 40px;
        text-align: center;
    }
    .purchase-form-circle-button {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background-color: #40B1D1;
        padding: 0;
        color: $white;
        line-height: 24px;
        border: 1px solid transparent;
        display: inline-flex;
        justify-content: center;

        &:disabled {
            border: 1px solid #D0DCEF;
            color: #D0DCEF;
            background-color: $white;
            opacity: 1;

            svg {
                fill: #D0DCEF;
            }
        }

        svg {
            align-self: center;
            height: 10px;
            fill: $white;
            margin: auto;
        }
    }
    .purchase-numeric-display-input-value {
        width: 80px;
        position: relative;
    }
    .purchase-numeric-step-input-wrapper {
        input {
            width: 40px;
        }
    }
}

app-datepicker-old {
    position: relative;
    z-index: 11;
}

.text-area-resize-none {
    resize: none;
}

.data-picker-width {
    width: 300px;
}

.scrollable-textarea {
    overflow-y: auto;
    resize: none;
    max-height: 200px;
}
