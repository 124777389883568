.radio-input-half-width {
    .radio-input-wrapper {
        width: 50%;
    }
}

app-radio-button-group {
    display: flex;
    &.vertical {
        flex-direction: column;
    }
}

.radio-input-wrapper {
    min-height: 22px;
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }


    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        line-height: 16px;
        font-size: 14px;
        color: $black-dark;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #fff;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
    }

    [type="radio"]:checked + label:before {
        background-color: #40B1D1;
        border: 1px solid transparent;
    }

    [type="radio"]:not(:checked) + label:before {
        border: 1px solid #546179;
    }

    [type="radio"]:checked + label:after {
        background-color: #fff;

    }
}


//TODO Move hex color values to variables
