


label,
.label {
	display: block;
	margin: 0;
    padding: 0;

	line-height: $lh;
	font-size  : $fs;
	font-weight: normal;
    white-space: nowrap;

 	background: transparent;
    color: $black-dark;

    &.primary {
        background: transparent;
        color: $black-dark;
        &:hover {
            color: $blue-dark;
        }
    }
    &.secondary {
        background: transparent;
        color: $grey-dark;
    }
    &.success {
        background: transparent;
        color: $blue;
        &:hover {
            color: $blue-dark;
        }
    }

    &.warning {
        background: transparent;
        color: $black-dark;
        &:hover {
            color: $red-dark;
        }
    }

    .alert {
        background: transparent;
        color: $red;
    }

    &.middle {
        margin : 0 0 1rem;
        padding: 0.5625rem 0;
    }
}



.label-warning {
    background: transparent;
    color: $black-dark;
    &:hover {
        color: $red-dark;
    }
}
