
// 1. Title
// 2. Favorites tool box
// 3. Reset selected options button

.title_wrapper {
    padding: $basis*2 0;
    @include clearfix;
    overflow: visible !important;

    .title_container {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
    }

    .titleToolbox_wrapper {
        float: right;
        display: block;
        vertical-align: middle;

        .favorites_wrapper {
            vertical-align: middle;
            display: inline-block;
        }

        .headerButton_wrapper {
            display: inline-block;
            margin-left: $lh;
            float: right;
        }
    }
}

