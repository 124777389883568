

.draggableItem {
    cursor: move; /* fallback if grab cursor is unsupported (IE) */
    cursor: grab;
}
.dragItem {
    cursor: move;
}

.dragItemPlaceholder {
    background-color: rgba($blue, 0.4) !important;
    border: none !important;
    width : $basis*20;
    height: $basis * 7;
    overflow: hidden;

    & > * {
        visibility: hidden;
    }
    .dropArea {
        visibility: hidden;
    }
}

.detectRightEdge {
    position: absolute;
    top   : 0;
    right : 0;
    bottom: 0;
    height: 100%;
}

.detectLeftEdge {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
}

.dropArea {
    display: inline-block;
    overflow: hidden;
    max-width: 0;
    max-height: 0;
    @include transform(scale(0));
    @include transition(transform 0.2s ease-in-out, max-width 0.2s ease-in-out, max-height 0.2s ease-in-out);

    margin: 0;
    background-color: $drop-field-bg;
    color: $blue-dark;

     // Hack for IE11
     @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        transition: none;
    }

    &.is-active {
        max-width: unset;
        max-height: unset;
        @include transform(scale(1));
        @include transition(transform 0.5s ease-in-out, max-width 0.5s ease-in-out, max-height 0.5s ease-in-out);

        padding: 0 $basis/2;
        border: 2px dashed $blue-dark;
        margin: 0 $basis/2;

        // Hack for IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width : 100%;
            max-height: 100%;
            transition: none;
        }
    }

    .dropArea_content {
        padding: ($basis - 2); // reduce for border
        display: inline-block;
    }
    .icon_container {
        margin-right: $basis/2;
    }
    .dropAreaMessage {
        display: inline-block;
    }
}



.dragNdropCard {
    position: relative;
    display: inline-block;
    padding: ($basis/1.5); // 8px
    border: 1px solid $grey-dark;

    background: $white;

    .dragNdropCardHeader {
        position: relative;
        padding : 0 $basis*2;
        @include clearfix;
        white-space: nowrap;


        .icon_container{
            position: absolute;
            top : 50%;
            left: 0;
            @include transform(translateY(-50%));

            display: block;
            width: $basis;
            height: $basis;
            font-size: $fs-small;
        }

        .dragNdropCardLabel {
            display: inline-block;
            white-space: normal;
        }
        .closeButton_container {
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
}
