
$width: 28px; // Change Me
$height: $width / 2.5;

.switch_container {
    display: block;
    cursor: pointer;
    .switch_label {
        display: inline-block;
        font-size: $fs;
        color: $black;
        cursor: pointer;
    }

    .switch_content {
        position: relative;
        display: inline-block;
        width: $width;
        height: $height;
        margin: (($height * 1.5 - $height) / 2) (($height * 1.5) / 2) (($height * 1.5 - $height) / 2) (($height * 1.5 - $height) / 2);
        overflow: visible;



        cursor: pointer;
        text-align: center;
        vertical-align: middle;
    }


    input[type='checkbox'] {
        display: none;

        &:checked ~ .switch {
            background: rgba(64, 177, 209, 0.67);
            transition: .5s;
        }

        &:checked ~ .toggle {
            @include box-shadow( inset 0 0 0px 1.5px $blue);
            left: ($width - ($height * 1.4 - $height) / 2) - $height;
            transition: .5s;
        }
    }

    .switch {
        display: block;
        width: $width;
        height: $height;

        background: rgba(138, 147, 164, 0.67);
        border-radius: $height / 2;
        position: absolute;
        top: 0;
        transition: .5s;
        cursor: pointer;
    }

    .toggle {
        position: absolute;
        top: ($height * 1.4 - $height) / -2;
        left: ($height * 1.4 - $height) / -2;

        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: white;

        @include box-shadow( inset 0 0 0px 1.5px #8a93a4);
        transition: .5s;
        cursor: pointer;
    }
}



