.checkboxLabelGroup {
    /* Base for label styling */
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: $lh;
        margin: 0;

        vertical-align: middle;
        font-size: $fs;
        line-height: $lh;
        cursor: pointer;
        white-space: pre-wrap;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0px;

        width: 15px;
        height: 15px;
        border: 1px solid $grey-dark;
        background: #fff;
        @include border-radius(0);
        box-sizing: border-box;
    }

    [type="checkbox"]:checked + label:before {
        background-color: $blue;
        border: $blue;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        font-family: 'Font Awesome 5 Pro';
        content: "\f00c";

        position: absolute;
        top: 4px;
        left: 2px;

        display: inline;
        font-size: 9px;
        line-height: $fs;
        text-indent: 1px;
        font-weight: 600;
        color: $white;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    [type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    /* disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before,
    [type="checkbox"]:checked.disabled + label:before {
        //box-shadow: none;
        //border-color: $grey;
        //background-color: $grey-light;
    }
    [type="checkbox"]:disabled:checked + label:after,
    [type="checkbox"]:checked.disabled + label:after {
        color: $grey-light;
    }
    [type="checkbox"]:disabled + label,
    [type="checkbox"].disabled + label {
        //color: $grey-light;
    }

    /* accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
        // @include box-shadow(inset 0px 0.5px 2px 0px rgba(102,102,102,1));
    }

    /* hover style just for information */
    label:hover:before {
        // @include box-shadow(inset 0px 0.5px 2px 0px rgba(102,102,102,1));
    }

}


.checkboxList {
    li {
        margin-bottom: $lh/4;
        .ss-checkbox {
            padding-left: 25px;
        }
    }
}

.half-check {
    .checkboxLabelGroup [type="checkbox"]:not(:checked) + label:after,
    .checkboxLabelGroup [type="checkbox"]:checked + label:after {
        content: "\f068";
    }
    * .checkboxLabelGroup [type="checkbox"]:not(:checked) + label:after,
    * .checkboxLabelGroup [type="checkbox"]:checked + label:after {
        content: "\f068";
    }
}

.checkBoxWrapper label {
    width: 95px;
}
