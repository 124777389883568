app-pass-card-management {
    .page-wrapper {
        position: initial;
        min-height: 100vh;
    }
    .pageContent_wrapper {
        overflow-y: initial;
        position: initial;
    }
    .full-width-layout .pageContent_wrapper .mainContent_wrapper {
        position: initial;
        padding-bottom: 0;
    }

    .line-height-22px {
        line-height: 22px;
    }
    .height-22px {
        height: 22px;
    }

    .empty-state {
        padding-top: 80px;
        h2 {
            font-size: 22px;
            line-height: 28px;
            color: rgba(123, 136, 163, 1);
        }
        p {
            font-size: 16px;
            line-height: 18px;
            color: rgba(123, 136, 163, 0.75);
        }

    }
    .filter-pass-cards-btn {
        cursor: pointer;
        svg path {
            fill:#3D495F;
        }
        span {
            color: #3D495F;
        }
        &:hover, &.selected  {
            svg path {
                fill: #40B1D1;
            }
            span {
                color: #40B1D1;
            }
        }
    }
    .filter-pass-cards-sidebar {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 290px;
        top: 50px;
        height: calc(100vh - 50px);
        background-color: #D0DCEF;
        z-index: 3;
        transition: transform ease 0.4s;
        will-change: transform;
        .close-icon {
            align-self: flex-end;
            cursor: pointer;

            path {
                fill: #546179;
            }
            &:hover {
                path {
                    fill: #000000;
                }
            }
        }

        .filter-email-input {
            width: 100%;
        }

        .focused {
            border: 1px solid $blue;
        }

        .md-drppicker {
            position: fixed !important;
        }
    }
    .filter-action-buttons-wrapper {
        .button {
            font-size: 14px;
            height: 36px;
            min-width: calc(50% - 8px);
            line-height: 100%;
            &.ghost-button {
                margin-right: 8px;
            }
        }
    }
    .filter-option {
        .label {
            margin-bottom: 4px;
        }
    }
    .filter-wrapper {
        height: calc(100% - 12px - 32px - 16px);
    }

    .pass-card-management-header-actions {
        align-items: end;
        justify-content: space-between;
        .filter-tags-wrapper {
            color: $black-dark;
            flex-wrap: wrap;
        }
        .filter-tag {
            background-color: #e6e6e6;
            padding: 2px 12px;
            border-radius: 24px;
            cursor: pointer;
            line-height: initial;
            transition: 0.1s background-color, 0.1s color;
            &:hover, &.selected {
                background-color: #40B1D1;
                color: $white;
            }
        }
    }

    .animate-out {
        transform: translateX(100%);
    }
    .animate-in {
        transform: translateX(0);
    }
    .table-action {
        cursor: pointer;
        height: 32px;
        padding: 0 16px;
        line-height: 32px;
        &:hover {
            background: rgba(204, 204, 204, 0.2);
            font-weight: 600;
        }
    }
    .show-table-actions {
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 8px;
    }
    .show-table-actions.selected {
        cursor: pointer;
        svg circle {
            fill: #40B1D1;
        }
    }
    .table-actions-dropdown-wrapper {
        position:absolute;
        top: 24px;
        z-index: 10;
        left:0;
        background-color: $white;
        width: 205px;
        box-shadow: 0 3px 5px rgba(84, 97, 121, 0.3);
        border:1px solid #D0DCEF
    }

    .focused {
        .dropdownHeader_wrapper {
            border: 1px solid $blue;
        }
    }
    app-datepicker-old.focused .datepicker_container input {
        border: 1px solid $blue;
    }
}

app-pass-cards-table {
    .table-of-pass-cards {
        max-height: calc(100vh - 50px - 70px - 64px - 16px);
        overflow-y: auto;
        display: block;
        @extend .overflow;
        .table_container {
            margin-bottom: 0;
        }
        .table_stripped, thead {
            border-top: 0;
        }
        th {
            position:-webkit-sticky;
            position:sticky;
            top: -1px;
            background-color: #798aa3;
            z-index: 2;
        }
    }
}

.edit-pass-cards-dialog {
    .content_wrapper {
        min-width: 520px;
        min-height: 128px;
    }
    .footer_wrapper {
        padding-bottom: 0;
        button {
            min-width: 150px;
            width: initial;
        }
    }
}
