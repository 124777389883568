.app-shopping-cart-header {
    background-color: #798AA3;
    padding: 7px 16px;
    margin: -1px -1px 0 -1px;
}

//Empty states
.app-shopping-cart-secondary-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #EAF0FA;
    min-height: 112px;
    svg {
        height: 36px;
        fill: #ABBAD1;
        margin-bottom: 8px;
    }

    p {
        color: #ABBAD1;
    }
}

.app-shopping-cart-balance-due-wrapper.empty-state {
    padding: 7px 16px;
}
.order-form-items-wrapper.empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 50px);

    svg {
        height: 32px;
        fill: #ABBAD1;
        margin-bottom: 10px;
    }

    p {
        color: #ABBAD1;
    }
}
.app-shopping-cart-payment-items-wrapper {
    background-color: #eaf0fa;
    border-bottom: 1px solid #d0dcef;
    border-top: 1px solid #d0dcef;
}



.no-payment-items.no-shopping-cart-items {
    .app-shopping-cart-item-wrapper {
        height: 447px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 35px;
    }
}

.no-payment-items.zero-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 415px;
    }
}
.no-payment-items.one-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 367px;
    }
}
.no-payment-items.two-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 343px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}
.no-payment-items.three-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 343px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}
.no-payment-items.four-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 343px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}

.one-payment-item.zero-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 471px;
    }
}
.one-payment-item.no-shopping-cart-items {
    .app-shopping-cart-item-wrapper {
        height: 479px;
    }
}
.one-payment-item.one-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 423px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 32px;
    }
}
.one-payment-item.two-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 399px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 32px;
    }
}
.one-payment-item.three-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 399px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 32px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}
.one-payment-item.four-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 399px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 32px;
    }
}

.two-payment-items.zero-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 447px;
    }
}
.two-payment-items.one-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 395px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 56px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }

}
.two-payment-items.two-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 375px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}
.two-payment-items.three-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 375px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}
.two-payment-items.four-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 351px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
}

.multiple-payment-items.zero-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 447px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
}
.multiple-payment-items.one-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 399px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }

}
.multiple-payment-items.two-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 375px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }

}
.multiple-payment-items.three-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 375px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}
.multiple-payment-items.four-cost-row-items {
    .app-shopping-cart-item-wrapper {
        height: 375px;
    }
    .app-shopping-cart-payment-item-wrapper {
        height: 56px;
    }
    .app-shopping-cart-item-cost-wrapper > div {
        height: 76px;
    }
}



// Edit mode of shopping cart item
.shopping-cart-item-edit-mode {
    border: 2px solid #40B1D1;
    position: sticky;
    top:0;
}
.shopping-cart-item-view-mode {
    border: 2px solid transparent;
}
.fa-times, .fa-pencil {
    cursor: pointer;
    &:hover {
        color: #333333;
    }
}
.vertical-divider {
    height: inherit;
    width: 1px;
    background-color: rgba(208, 220, 239, 0.8);
    margin: 0 16px;
}

.app-shopping-cart-balance-due-wrapper {
    border: 1px solid #D0DCEF;
    background-color: #D0DCEF;
    padding: 8px 16px 8px 16px;
    justify-content: space-between;
    display: flex;
}
.app-shopping-cart-item {
    .shopping-cart-item-header {
        display: flex;
        align-items: center;
        padding: 2px 16px 8px 16px;
        h5 {
            width: calc(100% - 32px);
            padding: 8px 0 0 0;
        }

        .shopping-cart-item-icons-wrapper {
            width: 36px;
            display: flex;
            justify-content: space-between;
            color: #546179;
            font-size: 12px;
            i {
                cursor: pointer;
            }
            &.disabled {
                opacity: 0.25;
                pointer-events: none;
            }
        }
    }
    &:last-child {
        .shopping-cart-item-body {
            border-bottom: 0;
        }
    }
}
.shopping-cart-item-direction-wrapper {
    display: flex;
}
.shopping-cart-item-direction {
    align-self: center;
    p {
        display: flex;
        align-items: baseline;
        height: 22px;
        color: $black-dark;
    }
    i {
        color: #546179;
        width: 10px;
        margin-right: 10px;
        text-align: center;
        display: inline-block;
    }
    .shopping-cart-item-time-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 36px);
        display: inline-block;
    }
}
.full-width {
    .shopping-cart-item-direction {
        width: 100%;
    }
}
.half-width {
    .shopping-cart-item-direction {
        width: calc(50% - 18px);
    }
}
.shopping-cart-item-direction-wrapper {
    display: flex;
}
.shopping-cart-item-time {
    margin-bottom: 8px;
    display: inline-block;
}
.shopping-cart-item-body {
    border-bottom: 1px solid #dddddd;

}
.app-shopping-cart-item-cost-item {
    justify-content: space-between;
}


.border-bottom-2px {
    border-bottom: 2px solid #546179;
}
.border-1px-white {
    border: 1px solid $white;
}


.credit-card-security-code-wrapper {
    svg {
        width: 50px;
    }
    .purchase-svg-wrapper {
        .st0 {
            fill: #D2DDE5;
        }

        .st1 {
            fill: #5D5D5D;
        }

        .st2 {
            fill: $white;
        }

        .st3 {
            fill: #848484;
        }

        .st4 {
            fill: #E3EFF2;
        }

        .st5 {
            fill: #020202;
        }

        .st6 {
            fill: #FF0000;
        }
    }
}

.discount-input-wrapper {
    input[type="text"] {
        width: calc(100% - 24px);
    }
}

.dark-theme {
    .app-shopping-cart-header {
        background-color: #3D495F;
        margin: initial;
    }
    .app-shopping-cart-item-wrapper {
        background-color: #EAF0FA;
        svg {
            path {
                fill: #3D495F;
            }
        }
    }
    .app-shopping-cart-item-cost-wrapper {
        background-color: #EAF0FA;
    }
    .shopping-cart-footer-buttons-wrapper {
        background-color: #3D495F;
    }

    .empty-state p {
        color: rgba(61, 73, 95 ,0.8);
    }
    .app-shopping-cart-secondary-empty-state {
        background-color: #D0DCEF;
        svg {
            fill: #3D495F;
        }
        p {
            color: rgba(61, 73, 95 ,0.8);
        }
    }
    .app-shopping-cart-balance-due-wrapper {
        background-color: #ABBAD1;
        border: medium none;
    }
    .ghost-button {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;
        &:hover {
            border: 1px solid $white;
        }
    }

    .ghost-button-reset {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;
        &:hover {
            border: 1px solid $white;
        }
    }
    .invisible-button {
        color: $white;

    }
}
