.order-report-sidebar-favorite {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 290px;
    top: 128px;
    height: calc(100vh - 128px);
    background-color: $white;
    z-index: 20;
    border-top: 1px solid $border;
    border-left: 1px solid $border;
    transition: transform ease 0.4s;
    will-change: transform;

    .close-icon {
        align-self: flex-end;
        cursor: pointer;

        path {
            fill: #546179;
        }

        &:hover {
            path {
                fill: #000000;
            }
        }
    }

    .order-report-sidebar-favorite-action-buttons-wrapper {
        align-items: center;
        justify-content: center;

        .button {
            font-size: 14px;
            height: 36px;
            line-height: 100%;
            min-width: calc(50% - 8px);

            &.ghost-button {
                margin-right: 8px;
            }
        }
    }

    .favorite_sidebar_container {
        height: calc(100% - 80px);

        .accordion {
            &.bordered {
                width: 100%;
            }
        }

        .search_wrapper {
            display: block;
            position: static;
            top: 0;
            z-index: 1;
            background-color: #fff;
            border-bottom: 1px solid #ABBAD1;
            margin-bottom: -1px;
            padding: 24px 16px 24px 16px;
        }

        .accordion_wrapper {
            overflow: auto;
            height: calc(100% - 80px);

            .accordion {
                &.bordered {
                    border-color: $border;

                    .card_title,
                    .card_content {
                        border-left-width: 0;
                        border-right-width: 0;
                        border-color: $border;

                        .cardContent_container {
                            padding: $basis $basis * 1.5;
                            padding-right: 0;
                        }
                    }
                }

                .accordion_card {
                    .card_title {
                        padding-left: $basis*3;

                        &:before {
                            text-align: left;
                            left: $basis;
                            width: $basis*3;
                            color: $grey-dark;
                        }
                    }

                    .accordion-item {
                        li {
                            cursor: pointer;
                            padding: 5px;

                            &:hover {
                                color: $blue-dark;
                            }
                        }

                        .selected {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    &.animate-out {
        transform: translateX(100%);
    }

    &.animate-in {
        transform: translateX(0);
    }
}







