
.searchGroup_container {
    clear: both;
    position: relative;

    display  : inline-block;
    height   : 100%;
    min-width: $lh*13;
    vertical-align: middle;

    &.is-inverted {
        .searchGroup_iconWrapper {
            float: right;
        }
        .searchGroup_input {
            float: right;
            left: auto;
            right: 0;

        }
        .open-search &{
            .searchGroup_input {
                padding: 0 $lh*2 0 $lh;
            }
        }
        .iconImage_container{
            padding-top: 1.5px;
            padding-left: 2px;
        }
    }

    .searchGroup_input {
        position: absolute;
        top  : 0;
        left: 0;

        width  : 0;
        height : 100%;
        border : 0;
        padding: 0;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include transition(all 0.75s ease-in-out);

    }

    .open-search & {
        .searchGroup_input {
            width: 100%;
            padding: 0 $lh*2 0 $lh;
            @include transition(all 0.75s ease-in-out);
        }
    }

    .searchGroup_iconWrapper {
        position: relative;
        float: right;
        z-index: 200;

        .widgetItem_content {
            background: transparent !important; // fix input animation bug (staggering effect);
            .iconImage_container {
                // icon color
                @include transition(color 0.95s );
            }
        }
    }
    .searchGroupResultsDropdown_wrapper {
        z-index: 10;

        position: absolute;
        top  : $header-height;
        right: 0;
        left : 0;

        height: 100%;
        width:  100%;
    }
}

.searchResultsList_container {
    display: block;
    width  : 100%;
    height : 0;
    overflow: hidden;
    overflow-y: auto;

    background-color: $white;
    @include box-shadow (0px 2px 5px 0px #cccccc);
    @include transition (all 1s ease-out);

    .showSearchResults & {
        padding: $lh/4 0;
        height: auto;
        max-height: $lh*15;
        @include transition (all 1s ease-out);
    }
    .searchResultLi {
        display: block;
        .searchResultLabel {
            display: block;
            padding: $lh/4 $lh*2 $lh/4 $lh;
            width: 100%;

            &.searchResultLink {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: $black-dark;
                &:hover {
                    color: $blue-dark;
                }

            }
        }
        .searchResultLabelDropDown {
            display: inline-block;
            padding: $lh/4 $lh*2 $lh/4 $lh;
            width: 90%;
        }
        .searchResultLabelDropDownNotifications {
            display: inline-block;
            padding: $lh/1.6 $lh/2 $lh/1.6 $lh;
            width: 100%;
        }

        .searchResultLabelDropDownNotifications:hover {
            color: #40B1D1;
        }
        .searchResultIconDropDown {
            padding: $lh/6.5 $lh/2.9 $lh/10 $lh/2.9;
        }
    }
}

.box-shadow-none {
    box-shadow: none !important;
}

.notificationListMessage_label {
    max-width: 35ch; /* limits to approximately 35 characters */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchGroup_input_icon {
    position:relative;
    float: right;
}

.searchResultsList_container_icon {
    position: absolute;
    right: 15px;
}

.notification-not-displayed {
    font-weight: bold !important;
}

.notification-displayed {
    color: $grey-alt !important;
}

.notification-button {
    display: none;
}

.yes_no_content_wrapper .fs-16 {
    word-wrap: break-word;
    white-space: normal;
}

.notification-container {
    margin-bottom: 10px;
    background: rgb(91, 107, 137);
    border-radius: 25px;
}

.notification-alert {
    color: white;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.bell-counter {
    position: fixed;
    margin-top: 12px;
    margin-left: 1px;
    font-size: 14px;
}

.app-search-bar {
    display: inline-block;
    width: fit-content;
    margin-left: 6px;
}
