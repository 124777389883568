.quickSellingButton_container {

    .simplebar-track.simplebar-horizontal {
        pointer-events: auto;
    }

    .table_container {
        margin-bottom: 40px;
        padding-bottom: 0;
    }
}

.quickSellingButtonProductButton {
    display: none;
}
