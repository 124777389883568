.m-auto {
    margin: auto;
}
.mr-20px {
    margin-right: 20px;
}
.mb-20px {
    margin-bottom: 20px;
}
.mb--25px {
    margin-bottom: -25px;
}
.mr-16px {
    margin-right: 16px;
}
.ml-16px {
    margin-left: 16px;
}
.mb-16px {
    margin-bottom: 16px;
}
.mb-20px {
    margin-bottom: 20px;
}
.mb--5px {
    margin-bottom: -5px;
}
.mb-5px {
    margin-bottom: 5px;
}
.mb-30px {
    margin-bottom: 30px !important;
}
.mb-10px {
    margin-bottom: 10px;
}
.ml-20px {
    margin-left: 20px;
}
.mb-8px {
    margin-bottom: 8px;
}
.mr-8px {
    margin-right: 8px;
}
.ml-8px {
    margin-left: 8px;
}
.mb-4px {
    margin-bottom: 4px;
}
.ml-4px {
    margin-left: 4px;
}
.mr-4px {
    margin-right: 4px;
}
.mt-2px {
    margin-top: 2px;
}
.mt-5px {
    margin-top: 5px;
}
.mt-16px {
    margin-top: 16px;
}
.mt-18px {
    margin-top: 18px;
}
.mt-9px {
    margin-top: 9px;
}
.mt-10px {
    margin-top: 10px;
}
.mt-20px {
    margin-top: 20px;
}
.mt--15px {
    margin-top: -15px;
}
.mt--51px {
    margin-top: -51px;
}
.ml-10px {
    margin-left: 10px;
}
.ml--10px {
    margin-left: -10px;
}
.ml--5px {
    margin-left: -5px;
}
.ml--25px {
    margin-left: -25px;
}
.ml--26px {
    margin-left: -26px;
}
.mr-10px {
    margin-right: 10px;
}
.ml-4rem {
    margin-left: 4rem;
}
.mt-3px {
    margin-top: 3px;
}
.ml--15px {
    margin-left: -15px;
}
.mr-21px {
    margin-right: 21px;
}

.ml-5px {
    margin-left: 5px;
}
