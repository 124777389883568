/*  Table Component */

/*
    table_wrapper
    |
    table_container // don't have to be inside
    |__scrollbar
    |
    |__table_caption
    |  |__label
    |  |__toolbox
    |
    |__table_container  //padding bottom $lh;
    |  |__thead
    |  |  |__Max Width Cell-xs // 60px
    |  |  |__Sortable Columns
    |  |
    |  |__Highlighted Row
    |  |  |__Max Width Cell-xs
    |  |
    |  |_tr
    |  |  |__tr-active
    |  |  |__Max Width Cell-xs

*/


/* Types of Tables */
/*
  - striped table
    - row hover
    - row active
    - fixed width cell
    - sortable header

  - table-no-stripes
    - edit icon - header
    - fixed width cell
    - border around table
    - no stripes
    -
  - plain table  //no border, no stripes, small padding

*/
.orderReportTable_wrapper {
    .table-of-order-report {

        max-height: calc(100vh - 50px - 78px - 45px);

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 7px;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .35);
        }

        thead {
            position: sticky;
            z-index: 1;
            top: -1px;
        }

        tr {
            &.tr_highlighted {
                position: sticky;
                z-index: 1;
                top: 40px;
            }
        }
    }
}


.table_container {
    margin-bottom: $lh;
    max-width: 100%;


    &[data-simplebar] {
        padding-bottom: $basis;

        .horizontal {
            bottom: 1px;
        }
    }
}


// Foundation Overwrite :: BEGIN
table {
    width: auto;
    margin-bottom: 0;
    border-radius: 0;
    border-collapse: collapse;
    font-size: $fs;
    line-height: $basis*1.5; //18px

    caption {
        padding: $lh/4 $lh/2;
        font-weight: bold;
    }


    .table-scroll & {
        width: auto;
    }

    &.scroll {
        display: block;
        width: 100%;
        overflow-x: auto;
    }


    &.bordered {
        border: 1px solid $grey-dark;
    }

    &.unstriped tbody {

        background-color: #fff;

        tr {
            border-bottom: 0;
            border-bottom: 1px solid #f1f1f1;
            background-color: #fefefe;
        }
    }

    &.hover {
        thead, tfoot, tbody {
            tr:hover {
                background-color: $grey;
            }
        }

        &:not(.unstriped) {
            tr:nth-of-type(even):hover {
                background-color: $grey;
            }

        }
    }


    thead, tbody, tfoot {
        border: 1px solid $grey;
        background-color: #fff;
        color: $black-dark;

        tr {
            background: transparent;
            color: $black-dark;
            cursor: default;

            &.disabled {
                opacity: 0.5;
            }
        }

        th, td {
            padding: $fs-large/2 $fs-large;
            font-weight: normal;
            text-align: left;
        }
    }

    tbody tr:nth-child(even) {
        border-bottom: unset;
        background-color: #fff;
    }

    @media screen and (max-width: 1200px) {
        .stack {
            thead {
                display: none;
            }

            tfoot {
                display: none;
            }

            tr, th, td {
                display: block;
            }

            td {
                border-top: 0;
            }
        }
    }
}

// Foundation Overwrite :: END


table {
    // Highlighted Row
    .tr_highlighted {
        background-color: #acbad1 !important;
        color: white;
    }

    // Icon from Kiosk Config Details Page
    .editTableIcon_wrapper {
        position: relative;
        padding: $lh/4 $fs;

        .editTableIcon_container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: $lh*1.5;

            display: block;
            background-color: #eaf0fa;
            text-align: center;

            &:hover {
                .editTableIcon {
                    color: $blue-dark;
                }
            }

            .editTableIcon {
                display: block;
                padding: $lh/4 0;
                color: $grey-dark;
            }
        }
    }

    // Edit Table Icon :: end


    // Cell
    th, td {
        &.fixedWidthCell_xxl {
            width: $basis*40; //480px

            text-align: left;
            padding-left: 0;
            padding-right: 0;
        }

        &.fixedWidthCell_xl {
            width: $basis*30; //360px

            text-align: left;
            padding-left: 0;
            padding-right: 0;
        }

        &.fixedWidthCell_l {
            width: $basis*20; //120px

            text-align: left;
            padding-left: 0;
            padding-right: 0;
        }

        &.fixedWidthCell_s {
            width: $basis*5; //60px
            .tableIconContainer {
                display: block;
                color: $grey-dark;
                text-align: center;

                &:hover {
                    color: $blue-dark;
                }
            }
        }

        &.fixedWidthCell_xs {
            width: $basis*3; //36px

            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }

    }

    // Cell with Icon :: end
}

.custom_billing_report_sub_header {
    background-color: $grey-ali-light !important;  
    color: $white;
}

.custom_billing_report_total_items_header{
    background-color: $selected-filters-area !important;
}



