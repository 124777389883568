

//TODO better organization
.standard-page-layout {
    .loader_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;
        background: $white;
        .loader_container {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
        }
    }

    .mainContent_container {
        margin-top: $basis*3; // 36px breadcrumbs
        width: 100%;

    }

    .pageTitle_container {
        margin-bottom: $lh*1.5;
    }

}

*, app-standard-page-layout {
    .pageTitle_container {
        padding: $basis*2 0;
    }
}

.table_wrapper {
    //margin-top: -51px; // TODO Remove this fix when the page header goes inside of the table wrapper
}

.alignToRightEdge {
    right: 0;
}

.alignBeforeInput {
    bottom: 30px;
}

.loader_container {
    padding: $basis*4 0;
}

app-discounts-container, app-included-costs-container, app-additional-costs-container {
    margin-bottom: -1px;
    display: block;
}
