.dropdown_container {
    //z-index: 1;
    position: relative;
    max-width: $lh*16;
    min-width: $lh*7.5;
    width : 100%;
    height: 100%;
    overflow : hidden;
    background-color: $white;
    font-size: $fs;

    &.dropdown-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    &.dropdown-fix-width { // dialogs, order reports, ...
        max-width: 150px;
    }
    &.dialog-fix-height { // dropdowns in dialogs
        max-height: $lh*10;
    }

    // Sizes
    &.dropdown_large {
        min-width: $lh*10 !important;
        max-width: unset;
        // Hack for IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: none;
        }
    }

    &.dropdown_m {
        width: $basis*9.6;
        min-width: unset;
        // Hack for IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-width: auto;
        }
    }

    &.dropdown_xs {
        width: $basis*5;
        min-width: unset;
          // Hack for IE11
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-width: auto;
        }
    }

    // State
    &.dropdown-active {
        overflow: visible;

        .dropdownHeader_wrapper {
            overflow: hidden;
            border-bottom-color: $grey-light;

            .dropdownHeader_container {
                min-height: $lh;
            }
        }
    }

    &.dropdown-loader {
        pointer-events: none;
        opacity: 1;

        .dropdownHeader_label {
            color: #a6a6ab;
            opacity: 1;
            &.input_element {
                border: 0;
            }
        }
        .icon_container {
            color: #40B1D1;
            opacity: 1;
            // Hide default & show loading icon
            .icon_content {
                &.default_icon {
                    display: none;
                }
                &.loading_icon {
                    display: block;
                }
            }

        }
    }




    .dropdownHeader_wrapper {
        border: 1px solid $grey-dark;
    }

    .dropdownList_wrapper {
        @include transform(translate3d(0, 0, 0)); // helps with rendering
        position: relative;
        height : 100%;
        z-index: 40;
    }
}

.input_placeholder::placeholder {
    color: $black-dark;
}

.dropdownHeader_container {
    z-index: 10;
    position: relative;

    display: block;
    padding: 0;

    .dropdownHeader_label {
        display: block;
        width  : 100%;
        padding: 5px $basis/1.5; //5px 8px
        padding-right: $basis*2 !important; //24px - sort/loading icon positioned right
        border: 0; // overide

        line-height: $basis*1.5; //18px;
        font-size: $fs;
        color: $black-dark;

        // overflow ellipsis
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.no-option-selected {
            color: #cacaca;
        }
        &:read-only {
            cursor: pointer;
            @include user-select (none);
        }
        &:hover {
            cursor: pointer;
            @include user-select (none);
        }
    }

    .icon_container {
        position: absolute;
        z-index: 10;
        top  : 50%;
        right: $basis/1.5;  //8px
        @include transform( translateY(-50%) );

        padding: 0;

        .icon_content {
            &.loading_icon {
                display: none;
            }
            &.default_icon {
                display: block;
                font-size: $fs;

                text-align    : center;
                vertical-align: middle;
                line-height: $lh;
                color: $grey-dark;
            }
        }
    }
}



.dropdownList_container {
    z-index: 20;
    position: absolute;
    max-height: $basis*29.1667;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    overflow  : hidden;
    overflow-y: auto;
    background-color: $white;

    // only for dNd // width and height and position calculated via JS
    .dropdown-fixed-option-list & {
        display : none; // bug while dNd - fixed position can't drag
        position: fixed;
    }

    .dropdown-active & {
        display: block;  // bug while dNd - fixed position can't drag
        height : 220px;
        border: 1px solid $grey-dark;

        // Hack for IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: auto;
        }
    }

    .dropdownList_content {
        padding: $lh/4 0;
    }
    .dropdownLi_container {
        display: block;
        &:hover {
            .dropdownLink_label,
            .dropdownLink {
                color: $blue;
            }
        }
        &.activeDropdownOption {
            font-weight: 600;
        }
        &.hoverDropdownOption {
           .dropdownLink {
               color: $blue;
           }
        }

        .dropdownLink_label {
            color: $black-dark;
        }
        .dropdownLink {
            display: inline-block;
            width: 100%;
            padding: $basis/3 $basis/1.5; // 4px 8px

            // if text can't fit one line
            font-size  : $fs;
            //white-space: nowrap;

            background-color: $white;
            color: $black-dark;


            line-height: 18px;
            margin-bottom: 0;
            padding: 6px 8px;
        }
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.35);
    }

    &::-webkit-scrollbar-track {
        background-color: $grey-light;
        border: 1px solid #d9e0ea;
    }

}

.mat-focused .mat-form-field-label {
    color: #08cbbf !important;
   }

  .mat-form-field-underline {
    background-color: #08cbbf !important;
  }

  .mat-form-field-ripple {
   background-color: #08cbbf !important;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #08cbbf !important;
  }

  .drop-down-height .dropdownList_container {
    max-height: 115px !important;
  }

  .max-width-unset .dropdown_container {
    max-width: unset !important;
  }
