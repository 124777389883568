
.sidebar_container {
    //margin-top: $basis*2;
    .search_wrapper {
        //margin-bottom: $basis*2;
        padding: 24px 0;
        display: block;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
        border-bottom: 1px solid #ABBAD1;
        margin-bottom: -1px;
    }
    // Accordion
    .accordion {
        &.bordered {
            border-color: $border;
            .card_title,
            .card_content {
                border-left-width: 0;
                border-right-width: 0;
                border-color: $border;

                .cardContent_container {
                    padding: $basis $basis * 1.5;
                    padding-right: 0;
                }
            }
        }

        .accordion_card {
            .card_title {
                padding-left: $basis*3;
                &:before {
                    text-align: left;
                    left: $basis;
                    width: $basis*3;
                    color: $grey-dark;
                }
            }
        }
    }

    .reportItemContainer {
        position: relative;
        display: inline-block;
        &:hover {
            display: block;
            .reportItemIconsContainer {
                display: block;
            }
            .reportItem {
                color: $blue-dark;
            }
        }
        .reportItem {
            color: $black-dark;
        }
        .reportItemIconsContainer {
            display: none;
            position: absolute;
            right: $basis/2;
            top: 50%;
            @include transform(translateY(-50%));
            line-height: 1;
        }
    }
}

