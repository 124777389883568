

.header_content {
    @extend .clearfix;

    .widgets_wrapper {
        float: right;
        padding: 0 $lh/2;
    }
}

// Menu Button
.menuButton_wrapper {
    display: inline-block;
    padding: 13px round($basis*1.33);
    vertical-align: middle;

    .menuButton_container {
        position: relative;
        display : inline-block;
        height: 16px;
        width : $lh;
        overflow: hidden;
        vertical-align: middle;


        .menuIcon_bar {
            position: absolute;
            display : block;
            width : 100%;
            height: 1.5px;
            background: $white;
            @include border-radius(20px);

            &.bar1 {
                top: 0;
            }
            &.bar2,
            &.bar3 {
                top: 7px;
                @include transition(transform(rotate) 0.35s ease-in-out);
            }
            &.bar4 {
                top: 14px;
            }
        }
    }

    &:hover {
        .menuIcon_bar {
            background-color: $blue-dark;
        }
    }
    &.menu_open {
        background-color: #e8ecfc;
        .menuIcon_bar {
            background-color: $black;

        }
        .bar1 {
            color: $black;
            top: 10px;
            width: 0%;
            left: 50%;
        }
        .bar2 {
            @include transform(rotate(45deg));
            @include transition(all 0.35s ease-in-out);
        }
        .bar3 {
            @include transform(rotate(-45deg));
            @include transition(all 0.35s ease-in-out);
        }
        .bar4 {
            top: 10px;
            width: 0%;
            left: 50%;

        }
        &:hover {
            background-color: #c5cdda;
        }
    }
}


// Logo
.logo_wrapper {
    display: inline-block;
    padding: 0 $lh/2;
    vertical-align: middle;
    height: 24px;

    .logo_container {
        height: $lh - 4;
        width: auto;
        .logo_image {
            display: inline-block;
            height: 100%;
            width: auto;
            vertical-align: middle;
            margin-top: -5px;
        }
    }
}



// Widgets
.widgets_container {
    // @include clearfix - doesn't work in Angular
    @extend .clearfix;

    .widgetItem_wrapper {
        float  : left; // float - out of normal flow of a document used for absolute positioning of child elements
        display: inline-block;

        .widgetItem_container {
            position: relative;
            width: 100%;
        }

        .headerDropdown_wrapper {
            z-index: 10;
            position: absolute;
            top  : $header-height;
            right: 0;

            height: 100%;
            width:  100%;
            min-width: $lh*17;
            max-height: $lh*20;
        }
    }
}


.widgetItem_content {
    display: inline-block;
    height : 100%;
    padding: 13px $lh/2;
    overflow: hidden;
    vertical-align: middle;

    &.widgetItem_active {
        background-color: $white;

        .hoverIconImage,
        .widgetIconImage {
            display: none !important;
        }
        .activeWidgetIconImage {
            display: inline-block !important;
        }
        .iconImage_container {
            color: $black-dark;
        }
    }
    .widgetItem_wrapper {
        display: inline-block;
    }
    .userWidget_wrapper {
        width: 100%;
        height: 100%;
    }

    .iconImage_container {
        display: inline;
        height: $lh;
        width : $lh;
        overflow: hidden;

        vertical-align: middle;
        line-height: 1;
        font-size: 18px;
        color: $white;
        img {
            height: 100%;
            width : auto;
        }
        .widgetIconImage {
            display: inline-block;
        }
        .hoverWidgetIconImage,
        .activeWidgetIconImage {
            // display: none;
             color: $black-dark;
        }
        &:hover {
            color: $blue-dark;
            .hoverWidgetIconImage {
                display: inline-block;
            }
            .widgetIconImage {
                display: none;
            }
        }
        &:active {
            color: $black-dark;
            .activeWidgetIconImage {
                color: $black-dark;
                display: inline-block;
            }
        }
    }
}




// Search
// TODO
.searchGroup_wrapper {
    display: block;
    width: 100%;
    height: 100%;
}



// Notifications
.widgetNotification_wrapper {
    .widgetNotification_container {
        position: relative;
        &.hasNewNotification {
            &:after {
                content: "";

                position: absolute;
                top  : 30%;
                right: $lh/2;

                width : $lh/2;
                height: $lh/2;

                background-color: $blue;
                border: 3px solid $blue;
                @include border-radius(50%);
                @include box-shadow (0px 2px 1px $grey-dark);

                font-size: 13px;
                z-index: 40;
            }
        }
    }
}

.header_customer {
    position: relative;
    z-index: 19;
    min-height: 1px;
    white-space: nowrap;
    border-top: 1px solid #ABBAD1;
    border-bottom: 1px solid #ABBAD1;
    background-color: #d0dcef;
    margin-top: 3em
}
