
.loader_container {
    overflow: hidden;
    text-align: center;
    background-color: rgba($white, 0.1);
    min-height: 60px;

    .loader_label {
        margin-top: $lh;
    }
}
.loader,
.loader:after {
	border-radius: 50%;
	width: $lh*2.5;
	height: $lh*2.5;
}
.loader {
	margin: 0 auto;
	font-size: 5px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(122,199,222, 0.2);
	border-right: 1.1em solid rgba(122,199,222, 0.2);
	border-bottom: 1.1em solid rgba(122,199,222, 0.2);
	border-left: 1.1em solid #7ac7de;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.sales-group-loader .loader_wrapper {
    position: relative !important;
    margin-top: 22rem;
}
