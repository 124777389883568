app-tree {
    display: block;
    flex-basis: 300px;
}

* {
    box-sizing: border-box;
    &::selection {
        background-color: #343d4e;
        color: $white;
    }
}
*, *::before, *::after {
    box-sizing: border-box !important;
}

html {
    font-size: 10px; //setting up rem
    min-height: 100vh;
}

body {
    line-height: 1;
    font-size  : $fs;
    font-family: $ff_sans-pro;
    font-weight: 400;
    color: $black-dark;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    margin: 0;
    overflow: hidden;


    min-height: 100vh;
}



// Headers :: BEGIN
h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    border: 0;

    font-weight: 600;
    line-height: 1.5;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

h1, .h1 {
    font-size: 22px;
    line-height: 1.272727;
}
h2, .h2 {
    font-size: 18px;
}

h3, .h3 {
    font-size: 16px; //16px
}

h4, .h4 {
    font-size: $fs; // 14px
    color: $grey-dark;
}
h5, .h5 {
    font-size: $fs; // 14px
    line-height: 1.28;
}
h6, .h6 {
    font-size: unset;

}

// Headers :: END




// Links
a {
    margin : 0;
    padding: 0;
    border : 0;
    line-height: $basis*2;
    outline: none;

    font-family: $ff_sans-pro;

    text-decoration: none;
    cursor: pointer;
    color: $blue;
    &:focus,
    &:hover,
    &:active {
        outline: none;
        color: $blue-dark;
    }
    &:disabled,
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
        &:hover {
            cursor: not-allowed;
        }
    }
}
.ctaLink {
    display: inline-block;
    color: $green;
    &:hover {
        color: $green-dark;
    }
}
.ctaLink_container {
    padding: $lh 0;
}
.activeLink {
    color: $blue-dark !important;
}

// Font style
i {
    font-style: normal;
}
.strong {
    font-weight: 600;
}

p {
    margin-bottom: 0;
    margin-top   : 0;
    font-size  : $fs;
    line-height: $basis*2;
    text-rendering: optimizeLegibility;

    -webkit-margin-before : 0;
    -webkit-margin-after  : 0;
}  // font-style :: end


img {
    vertical-align: baseline;
}


// List
ul {
    list-style  : none;
    margin      : 0;
    padding     : 0;
    line-height : unset;
    -webkit-padding-start : 0;
    -webkit-margin-before : 0;
    -webkit-margin-after  : 0;
}
.unorder-list,
.unordered-list {
    list-style-type: disc;
    -webkit-margin-before : $lh/4;
    -webkit-margin-after  : $lh/4;
    -webkit-margin-start  : 0;
    -webkit-margin-end    : 0;
    -webkit-padding-start : $lh;
}
// List :: end





// Form elements
[type='file'],
[type='checkbox'],
[type='radio'] {
    margin  : 0;
    padding : 0;
    line-height: $lh;
}




// Helper Classes

.clearfix {
    display: inline-block;
    &:after {
        content: " ";
        clear  : both;

        visibility : hidden;
        display    : block;
        font-size  : 0;
        height     : 0;
    }
}
* html .clearfix {
    height: 1%;
}
.clearfix {
    display: block;
}

// Positioning
.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.no-border {
    border: 0 !important;
}

// Layout
.center {
    text-align: center;
    margin    : 0 auto;
}
.hide {
    visibility: hidden;
    opacity: 0;
    display: block !important;
}

.hidden {
    display: inline-block;
    width: 0;
    height: 0;
    padding: 0;
}

.block {
    display: block;
}

.remove {
    display: none!important;
}

.disabled {
    //color: $grey-light;
    opacity: 0.5;
    pointer-events: none;
}

.float-right {
    float: right;
}

.float-left {
    float: left !important;
}




// Text
.text-right {
    text-align: right;
}

.text-right-flex {
    text-align: right;
    flex: 1;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-left-flex {
    text-align: left;
    flex: 1;
}

.error {
    color: $red-dark;
}

.font-black {
    color: $black-dark;
}

.horizontalSeparator-left {
    position: relative;
    padding-left: $lh !important;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        border-left: 1px solid $grey-dark;
    }
}


.frame-section {
    padding: 16px;
    border: 1px solid $selected-filters-area;
}

.frame-section-tight {
    border: 1px solid $selected-filters-area;
}

.frame-section-broad {
    padding: 24px;
    border: 1px solid $selected-filters-area;
}

.sticky-header {
    position: sticky;
    background: #FFF;
    top: 0;
    z-index: 19;
}

.sticky-footer {
    position: sticky;
    background: #FFF;
    bottom: 0;
    z-index: 19;
}

//Overflow
.overflow-hidden {
    overflow: hidden;
}

.flex-row {
    display: flex;
    flex-direction: row;
}