.app-system-options-page-wrapper {
    .mb-16 {
        margin-bottom: 16px;
    }
    h2 {
        color: $black-dark;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        padding: 0 0 16px 0;
    }
    .system-options-border {
        border: 1px solid #D0DCEF;
        padding: 16px;
    }
    .system-options-group {
        min-width: 405px;
        //min-height: 684px;
        height: calc(100vh - 50px - 28px - 48px - 16px - 72px);
        margin-right: 16px;
        flex-basis: 33.33333%;
        overflow-y: auto;
        &:last-child {
            margin-right: 0;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.35);
        }
    }
    .system-options-groups-wrapper {
        align-items: flex-start;
        display: flex;
    }
    .system-options-group-wrapper {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .system-options-item-wrapper, .component-item-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .system-options-additional-checkbox-inputs-wrapper {
        width: 100%;
    }
    .system-options-label, .component-label {
        padding-right: 16px;
        display: block;
        white-space: initial;
        line-height: 15px;
        flex-basis: 146px;
        color: #546179;
    }
    app-dropdown, .system-options-input-wrapper, .component-input-wrapper, .image-input-wrapper{
        flex-basis: calc(100% - 146px);
        app-image-upload, input {
            width: 100%;
        }
    }

    .component-login-locations-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

    }
    .login-location {
        border: 1px solid #D0DCEF;
        background-color: #EAF0FA;
        display:flex;
        align-items: center;
        margin: 0 8px 8px 0;
        padding: 6px 8px;
        .closeButton {
            cursor: pointer;
        }
        .closeButtonPosition {
            margin-left: 3px;
            margin-top: 3px;
        }
        svg {
            margin-left: 8px;
            line-height: 22px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .system-options-location-map-wrapper, .component-location-map-wrapper {
        min-width: 373px;
        height: 220px;
        width: 100%;
        border: 1px solid #ccc;
    }
    .system-option-action-buttons-wrapper {
        display: flex;
        justify-content: center;
        position: sticky;
        bottom: 0 ;
        padding: 16px;
        margin-top: 8px;
        background: $white;
        button {
            width: 136px;
            font-size: 16px;
            padding: 9px 0;
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .capacity-reaches-wrapper {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        app-dropdown {
            .dropdown_container {
                min-width: 100%;
            }
        }
    }
    .capacity-reached {
        display: flex;
        margin: 8px 20px 0 20px;
        align-items: center;
        width: calc(100% - 40px);
        span {
            margin: 0 8px;
        }
        input {
            width: calc(100% - 70px - 27px);
        }
        app-dropdown {
            input {
                width: 100%;
            }
        }
        .width-70 {
            width: 70px;
            flex-basis: initial;
        }

    }
}

app-yes-no-dialog a.secondary.button, app-yes-no-dialog a.primary.button {
    min-width: 150px;
    width: initial;
}
