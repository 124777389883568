// bp - break point
$bp-medium: 1200px;
$bp-small: 900px;

.small-12 {
    width: 100%;
}

.row.expanded {
    padding: 0 $lh*2;
    @media print, screen and (max-width: $bp-medium) {
        padding: 0 $lh;
    }
    .row.expanded {
        padding: 0;
        margin : 0 (-$lh);
    }
}

.column, .columns {
    padding-right: $lh;
    padding-left: $lh;
    @media print, screen and (min-width: $bp-small) {
        padding-right: $lh;
        padding-left: $lh;
    }
}

.row {
    max-width   : 1050px;
    margin-right: auto;
    margin-left : auto;
    .row::before, .row::after {
        display: table;
        content: ' ';
    }
    .row::after {
        clear: both;
    }
    .row {
        margin-right: -$lh;
        margin-left: -$lh;
        @media print, screen and (min-width: $bp-medium) {
            margin-right: -$lh;
            margin-left: -$lh;
        }
    }
}

.row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0;
}

@media print, screen and (min-width: $bp-small) {
    .row .row {
        margin-right: -$lh;
        margin-left: -$lh;
    }
}

@media print, screen and (min-width: $bp-medium) {
    .row .row {
        margin-right: -$lh;
        margin-left: -$lh;
    }
}

@media print, screen and (min-width: $bp-small) {
    .column, .columns {
        padding-right: $lh;
        padding-left: $lh;
    }
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left;
}

.column.row.row, .row.row.columns {
    float: none;
}

.row .column.row.row, .row .row.row.columns {
    margin-right : 0;
    margin-left  : 0;
    padding-right: 0;
    padding-left : 0;
}

@media print, screen and (min-width: $bp-small) {
    .medium-1 {
        width: 8.33333%;
    }
    .medium-push-1 {
        position: relative;
        left: 8.33333%;
    }
    .medium-pull-1 {
        position: relative;
        left: -8.33333%;
    }
    .medium-offset-0 {
        margin-left: 0;
    }
    .medium-2 {
        width: 16.66667%;
    }
    .medium-push-2 {
        position: relative;
        left: 16.66667%;
    }
    .medium-pull-2 {
        position: relative;
        left: -16.66667%;
    }
    .medium-offset-1 {
        margin-left: 8.33333%;
    }
    .medium-3 {
        width: 25%;
    }
    .medium-push-3 {
        position: relative;
        left: 25%;
    }
    .medium-pull-3 {
        position: relative;
        left: -25%;
    }
    .medium-offset-2 {
        margin-left: 16.66667%;
    }
    .medium-4 {
        width: 33.33333%;
    }
    .medium-push-4 {
        position: relative;
        left: 33.33333%;
    }
    .medium-pull-4 {
        position: relative;
        left: -33.33333%;
    }
    .medium-offset-3 {
        margin-left: 25%;
    }
    .medium-5 {
        width: 41.66667%;
    }
    .medium-push-5 {
        position: relative;
        left: 41.66667%;
    }
    .medium-pull-5 {
        position: relative;
        left: -41.66667%;
    }
    .medium-offset-4 {
        margin-left: 33.33333%;
    }
    .medium-6 {
        width: 50%;
    }
    .medium-push-6 {
        position: relative;
        left: 50%;
    }
    .medium-pull-6 {
        position: relative;
        left: -50%;
    }
    .medium-offset-5 {
        margin-left: 41.66667%;
    }
    .medium-7 {
        width: 58.33333%;
    }
    .medium-push-7 {
        position: relative;
        left: 58.33333%;
    }
    .medium-pull-7 {
        position: relative;
        left: -58.33333%;
    }
    .medium-offset-6 {
        margin-left: 50%;
    }
    .medium-8 {
        width: 66.66667%;
    }
    .medium-push-8 {
        position: relative;
        left: 66.66667%;
    }
    .medium-pull-8 {
        position: relative;
        left: -66.66667%;
    }
    .medium-offset-7 {
        margin-left: 58.33333%;
    }
    .medium-9 {
        width: 75%; }
    .medium-push-9 {
        position: relative;
        left: 75%;
    }
    .medium-pull-9 {
        position: relative;
        left: -75%;
    }
    .medium-offset-8 {
        margin-left: 66.66667%;
    }
    .medium-10 {
        width: 83.33333%;
    }
    .medium-push-10 {
        position: relative;
        left: 83.33333%;
    }
    .medium-pull-10 {
        position: relative;
        left: -83.33333%;
    }
    .medium-offset-9 {
        margin-left: 75%;
    }
    .medium-11 {
        width: 91.66667%;
    }
    .medium-push-11 {
        position: relative;
        left: 91.66667%;
    }
    .medium-pull-11 {
        position: relative;
        left: -91.66667%;
    }
    .medium-offset-10 {
        margin-left: 83.33333%;
    }
    .medium-12 {
        width: 100%;
    }
    .medium-offset-11 {
        margin-left: 91.66667%;
    }
    .medium-up-1 > .column, .medium-up-1 > .columns {
        float: left;
        width: 100%;
    }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
        clear: both;
    }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
        float: left;
    }
    .medium-up-2 > .column, .medium-up-2 > .columns {
        float: left;
        width: 50%;
    }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
        clear: both;
    }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
        float: left;
    }
    .medium-up-3 > .column, .medium-up-3 > .columns {
        float: left;
        width: 33.33333%;
    }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
        clear: both;
    }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
        float: left;
    }
    .medium-up-4 > .column, .medium-up-4 > .columns {
        float: left;
        width: 25%;
    }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
        clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
        float: left;
    }
    .medium-up-5 > .column, .medium-up-5 > .columns {
        float: left;
        width: 20%;
    }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
        clear: both;
    }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
        float: left;
    }
    .medium-up-6 > .column, .medium-up-6 > .columns {
        float: left;
        width: 16.66667%;
    }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
        clear: both;
    }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
        float: left;
    }
    .medium-up-7 > .column, .medium-up-7 > .columns {
        float: left;
        width: 14.28571%;
    }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
        clear: both;
    }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
        float: left;
    }
    .medium-up-8 > .column, .medium-up-8 > .columns {
        float: left;
        width: 12.5%;
    }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
        clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
        float: left;
    }
    .medium-collapse > .column, .medium-collapse > .columns {
        padding-right: 0;
        padding-left: 0;
    }
    .medium-collapse .row {
        margin-right: 0;
        margin-left: 0;
    }
    .expanded.row .medium-collapse.row {
        margin-right: 0;
        margin-left: 0;
    }
    .medium-uncollapse > .column, .medium-uncollapse > .columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
    .medium-centered {
        margin-right: auto;
        margin-left: auto;
    }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
        float: none;
        clear: both;
    }
    .medium-uncentered,
    .medium-push-0,
    .medium-pull-0 {
        position: static;
        float: left;
        margin-right: 0;
        margin-left: 0;
    }
}

@media print, screen and (min-width: $bp-medium) {
    .large-1 {
        width: 8.33333%;
    }
    .large-push-1 {
        position: relative;
        left: 8.33333%;
    }
    .large-pull-1 {
        position: relative;
        left: -8.33333%;
    }
    .large-offset-0 {
        margin-left: 0;
    }
    .large-2 {
        width: 16.66667%;
    }
    .large-push-2 {
        position: relative;
        left: 16.66667%;
    }
    .large-pull-2 {
        position: relative;
        left: -16.66667%;
    }
    .large-offset-1 {
        margin-left: 8.33333%;
    }
    .large-3 {
        width: 25%;
    }
    .large-push-3 {
        position: relative;
        left: 25%;
    }
    .large-pull-3 {
        position: relative;
        left: -25%;
    }
    .large-offset-2 {
        margin-left: 16.66667%;
    }
    .large-4 {
        width: 33.33333%;
    }
    .large-push-4 {
        position: relative;
        left: 33.33333%;
    }
    .large-pull-4 {
        position: relative;
        left: -33.33333%;
    }
    .large-offset-3 {
        margin-left: 25%;
    }
    .large-5 {
        width: 41.66667%;
    }
    .large-push-5 {
        position: relative;
        left: 41.66667%;
    }
    .large-pull-5 {
        position: relative;
        left: -41.66667%;
    }
    .large-offset-4 {
        margin-left: 33.33333%;
    }
    .large-6 {
        width: 50%;
    }
    .large-push-6 {
        position: relative;
        left: 50%;
    }
    .large-pull-6 {
        position: relative;
        left: -50%;
    }
    .large-offset-5 {
        margin-left: 41.66667%;
    }
    .large-7 {
        width: 58.33333%;
    }
    .large-push-7 {
        position: relative;
        left: 58.33333%;
    }
    .large-pull-7 {
        position: relative;
        left: -58.33333%;
    }
    .large-offset-6 {
        margin-left: 50%;
    }
    .large-8 {
        width: 66.66667%;
    }
    .large-push-8 {
        position: relative;
        left: 66.66667%;
    }
    .large-pull-8 {
        position: relative;
        left: -66.66667%;
    }
    .large-offset-7 {
        margin-left: 58.33333%;
    }
    .large-9 {
        width: 75%;
    }
    .large-push-9 {
        position: relative;
        left: 75%;
    }
    .large-pull-9 {
        position: relative;
        left: -75%;
    }
    .large-offset-8 {
        margin-left: 66.66667%;
    }
    .large-10 {
        width: 83.33333%;
    }
    .large-push-10 {
        position: relative;
        left: 83.33333%; }
    .large-pull-10 {
        position: relative;
        left: -83.33333%; }
    .large-offset-9 {
        margin-left: 75%; }
    .large-11 {
        width: 91.66667%; }
    .large-push-11 {
        position: relative;
        left: 91.66667%; }
    .large-pull-11 {
        position: relative;
        left: -91.66667%; }
    .large-offset-10 {
        margin-left: 83.33333%; }
    .large-12 {
        width: 100%;
    }
    .large-offset-11 {
        margin-left: 91.66667%;
    }
    .large-up-1 > .column, .large-up-1 > .columns {
        float: left;
        width: 100%;
    }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
        clear: both;
    }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
        float: left;
    }
    .large-up-2 > .column, .large-up-2 > .columns {
        float: left;
        width: 50%;
    }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
        clear: both;
    }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
        float: left;
    }
    .large-up-3 > .column, .large-up-3 > .columns {
        float: left;
        width: 33.33333%;
    }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
        clear: both;
    }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
        float: left;
    }
    .large-up-4 > .column, .large-up-4 > .columns {
        float: left;
        width: 25%;
    }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
        clear: both;
    }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
        float: left;
    }
    .large-up-5 > .column, .large-up-5 > .columns {
        float: left;
        width: 20%;
    }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
        clear: both;
    }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
        float: left;
    }
    .large-up-6 > .column, .large-up-6 > .columns {
        float: left;
        width: 16.66667%;
    }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
        clear: both;
    }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
        float: left;
    }
    .large-up-7 > .column, .large-up-7 > .columns {
        float: left;
        width: 14.28571%;
    }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
        clear: both;
    }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
        float: left;
    }
    .large-up-8 > .column, .large-up-8 > .columns {
        float: left;
        width: 12.5%;
    }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
        clear: none;
    }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
        clear: both;
    }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
        float: left;
    }
    .large-collapse > .column, .large-collapse > .columns {
        padding-right: 0;
        padding-left: 0;
    }
    .large-collapse .row {
        margin-right: 0;
        margin-left: 0;
    }
    .expanded.row .large-collapse.row {
        margin-right: 0;
        margin-left: 0;
    }
    .large-uncollapse > .column, .large-uncollapse > .columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
    .large-centered {
        margin-right: auto;
        margin-left: auto;
    }
    .large-centered, .large-centered:last-child:not(:first-child) {
        float: none;
        clear: both;
    }
    .large-uncentered,
    .large-push-0,
    .large-pull-0 {
        position: static;
        float: left;
        margin-right: 0;
        margin-left: 0;
    }
}

.column-block {
margin-bottom: 1.25rem; }

.column-block > :last-child {
    margin-bottom: 0;
}

@media print, screen and (min-width: $bp-small) {
    .column-block {
        margin-bottom: 1.875rem;
    }
    .column-block > :last-child {
        margin-bottom: 0;
    }
}

.boxed-layout {
    .mainContent_container {
        max-width: 1248px;
        margin: auto;
        display:block;
    }
}
