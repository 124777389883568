
.dialogSummary_wrapper {
	height: 100%;
	width : 100%;
	.loader_wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		.loader_container {
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
		}
	}


	.dialogSummary_container {
		padding: 0 $lh*4;

		.content_container {
			label {
				color: $grey-dark;
				line-height: 1;
			}
			.subtitle_container {
				padding: $lh/2 0;
			}
            .section-title {
                font-size: $fs;
                font-weight: 600;
            }
			.section_wrapper {
				margin-bottom: $lh;
				.section-row {
					padding-bottom: $lh/2;
					&:last-child {
						padding-bottom: 0;
					}
				}
			}
			.nested-table{
				margin: $lh/2 0;
			}
		}
	}
	.header_wrapper {
		.header_container {
			@include clearfix;
		}
	}

	.footer_wrapper {
		background-color: $lavander;
		padding: $lh $lh*4;
		.inlineCheckboxList_container {
			li {
				display: inline-block;
				padding-right: $lh/2;
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
}





.dynamicInputIconGroup {
	position: relative;
	display : inline-block;
	padding-right: $lh*2;

	&.edit-input {
		.input-active {
			display: inline-block;
		}
		.default-icon {
			display: none;
		}

		.no-borders-input {
			pointer-events: auto !important;
			padding: $lh/4 $lh/2 !important;
			border : 1px solid $black-light !important;
			&:focus {
				border: 1px solid $black-light;
				padding: $lh/4 $lh/2;
			}
		}
	}

	.dynamicInput_container {
		display: inline-block;
		.no-borders-input {
			pointer-events: none;
			border : 0;
			padding: 0;

			&:focus {
			    border : 0;
			    padding: 0;
			}
		}
	}


	.dynamicIcon_wrapper {
		position: absolute;
		bottom: 0;
		right : 0;
		.icon_container {
			color: $grey-dark;
		}
	}
	.input-active {
		display: none;
		.check-icon {
			color: $green;
		}
	}
}
