

.secondaryNav_wrapper {
    background-color: white;
    @include box-shadow(0px 2px 5px 0px #ccc);
    .row-padding {
        padding: 0 $nav-custom-padding !important;
        .cell {
            padding: 0 $basis*2;
        }
    }

    .secondaryNav_container {
        margin: 0 auto;
        position: relative;

        /* Remove Comments once HISTORY section enabled
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 16.6667%;
            bottom: 0;
            border-right: 0.5px solid $grey-light;
            width:1px;
            height: 100%;
        }
        */
        .secondaryNavColumns_container {
            width: 220px;
            &:first-child {
                width: 200px;
            }
        }
    }

    .secondaryNav_column {
        font-size: 14px;
        padding: $lh*2 0;

        .secondaryNav_label {
            font-size: $fs-nav-sec-title;
            font-weight: 600;
            padding: $lh/2 0;
            h4 {
                white-space: nowrap;
            }
        }

        .secondaryNav_list {
            margin: $lh/2 0;
            .secondaryNav_li {
                display: block;
                width: 100%;

                .secondaryNav_link {
                    display: block;
                    padding: 7px 0;

                    line-height: 16px;
                    font-size  : $fs-nav-sec-li;
                    font-weight: 500;

                    color: $black-dark;
                    white-space: nowrap;
                    &.secondaryNavActive {
                        font-weight: bold;
                        &:hover {
                            color: $black-dark;
                        }
                    }
                    &:hover {
                        color: $blue;
                    }
                    &:not([href]) {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
.primaryNav_wrapper {
    @include box-shadow(0px 2px 5px 0px #ccc);
}
