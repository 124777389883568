


.primaryNav_container {
    background-color: $primary-nav;


    // override default foundation grid
    .grid-padding-x {
        padding: 0 $nav-custom-padding;
        .cell {
            padding: 0 $basis*2;
        }
    }// override default foundation grid



    .primaryNavTab_wrapper {
        width: $nav-tab-max-width;

        &:first-child {
            margin-left: -$nav-custom-padding;
            padding-left: $nav-custom-padding;
            @media screen and (min-width: 1700px){
                max-width: 260px !important;
            }
        }
        &:hover {
            background-color: #a0aad4;
            .primaryNavTabLabel {
                color: $black-dark;
            }
            .primaryNavIcon {
                color: $black-dark !important;
            }

        }
        &.activePrimNav {
            background-color: $white;

            .primaryNavTabLabel {
                color: $black-dark;
            }
            .primaryNavIcon {
                color: $black-dark !important;
            }

        }
    }

    .primaryNav_tabContainer {
        position: relative;
        display: block;
        padding: $lh*2 0;
        white-space: nowrap;

        .primaryNavIcon_container {
            display: inline-block;
            width: 24px;
            vertical-align: middle;

            .primaryNavIcon {
                display: block;
                color: $grey-dark;
                height: $lh;
                font-size: $lh;
            }

        }
        .primaryNavTabLabel {
            display: inline-block;
            vertical-align: top;

            font-size: $fs-h3;
            font-weight: 600;
            color    : $grey-dark;
        }

    }
}
