app-order-qsb-content, app-order-long-form-content {
    .empty-state {
        width: 100%;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
            height: 32px;
            margin-bottom: 8px;
        }
        p {
            color: #ABBAD1;
        }
    }

}

app-step-1-shopping-cart-item-selector, app-step-2-order-info-and-payment-selector, app-trip-selector {
    height: 748px;
}

app-tiers-selector-content {
    height: auto;
}

app-tiers-selector {
    .empty-state {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 114px;
        padding: 24px;

        p {
            color: #ABBAD1;
            line-height: 18px;
        }

        svg {
            height: 32px;
            fill: #ABBAD1;
            margin-bottom: 16px;
        }
    }
}

app-discounts-selector-content {
     > div {
        max-height: 220px;
    }

    input[type="text"] {
        width: 221px;
        width: calc(100% - 24px);

        &:disabled {
            border: 1px solid #ABBAD1;
            background-color: #EAF0FA;
        }
    }

    button {
        &:disabled {
            opacity: 0.25;
        }
    }
    .applied-discounts-wrapper {
        max-height: 100px;
    }
}

app-step-1-buttons, app-step-2-buttons {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
}

app-shopping-cart-content {
    height: calc(100% + 2px);
}

app-custom-fields {
    > div{
        height: 437px;
    }
}

app-order-info {
    height: 311px;
    display: block;
    > div {
        height: calc(100% - 16px);
    }
}

app-payment-methods {
    > div {
        max-height: 307px;
    }
}

.order-form-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-bottom: 8px;
}

.order-form-item-date {
    display: flex;
    align-items: flex-start;
    min-height: 32px;
    margin-bottom: 8px;
}

.order-label {
    flex-basis: 155px;
    padding-right: 16px;
    white-space: pre-wrap;
}

.order-input-wrapper {
    flex-basis: calc(100% - 155px);
    > *, input[type="text"] {
        width: 100%;
    }
    .dropdown_container {
        min-width: initial;
    }
}

.wrapper-cloud-payment-device {
    margin-bottom: 120px;
    padding-left: 16px;
    padding-right: 16px;
}

.cloud-payment-device {
    width: 100%;
    .dropdown_container {
        max-width: 380px !important;
    }

    .dropdown-active {
        .dropdownList_container{
            height: 100px !important;
        }
    }
}




