.overflow {
    overflow-x: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.35);
    }
}
