
.email-templates-page-wrapper,
.email-templates-dialog-wrapper {

    .dialog-edit-email {
        @media screen and (min-width: 640px) {
            .fix-width-message {
                max-width: $lh * 15;
            }
        }

        .previewContent,
        textarea {
            max-width: calc(#{$lh * 32});
            height   : calc(#{$lh} * 10);
            overflow : hidden;
            overflow-y: auto;

            line-height: $lh;
        }
    } // dialog-edit-email



    // .dialog-edit-email
    // .dialog-preview-email
    .previewContainer {
        .label {
            color: $grey-dark;
            line-height: 1.25;
        }
    }
    .previewContent {
        max-width: calc(#{$lh * 32});
        padding: $basis/3 $basis/1.5; // 4px 8px
        border : 1px solid $black-light;

        line-height: $lh;
        background-color : rgba(238, 238, 238, 0.8);
    }
}

app-edit-email-html-template-dialog .reveal {
    min-width: 900px;
}
