
.kiosk-configuration-details-page {

    section {
        margin-bottom: $basis*3;
    }
    .section-title {
        @include clearfix;
        margin-bottom: $basis*2;
        .title_label {
            display: inline-block;
        }
    }

    .section-row {
        margin-bottom: $lh;

        label {
            line-height: 1;
            color: $grey-dark;
        }
    }





    //Dialog Tier Setup - Error Message
    .errorMessage_wrapper {
        margin-top: $lh;
        margin-bottom: $lh;
    }


    // Dialog Route Setup
    .removeRow_wrapper {
        padding-right: $basis*3;
    }
    .removeRowIcon {
        position: relative;
        .removeRowIcon_wrapper {
            position: absolute;
            bottom: 0;
            right: -($basis*3);
            height: $lh*1.5;
            width: $basis*3;
            text-align: center;

            .removeRowIconContainer {
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));

                display: block;
                height: $basis*2;
                color: $black-dark;
                &:hover {
                    color: $blue-dark;
                }
            }
        }
    }
}
