$dialog-basis: 8px;

.mainContent_wrapper.dialog-opened {
    overflow: hidden;
}

.reveal-overlay_wrapper {
    z-index: 100;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: block;
    overflow: hidden;
}

.reveal-overlay {
    display: flex; // overriding default foundation prop
    justify-content: center;

    height: 100%;
    overflow: hidden;
    overflow-y: visible;
    background-color: rgba(10, 10, 10, 0.45);

    .reveal-container {
        display: inline-block;
        //padding : $header-height*1.5 0 $header-height*2 0; // remove from the edges

        position: relative;
        top: 75px;
        max-height: calc(100% - 100px);
        //overflow-y: auto;
        .closeButton {
            font-size: 29px;
        }
    }
}

.reveal {
    z-index: 102;
    position: relative;
    display: inline-block;
    max-width: 900px;

    padding: 24px;
    border: 0;
    border-radius: 0;
    margin-right: auto;
    margin-left: auto;

    background-color: $white;
    overflow-y: visible;


    min-width: 400px;
    min-height: 216px;
    @include box-shadow(0px 3px 3px 3px rgba(102, 102, 102, 0.66));

    @media print, screen and (min-width: 40em) {
        width: auto;
        &.large {
            left: 50%;
            @include transform(translateX(-50%));

            width: auto;
            min-width: $width-summary-dialog;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            padding: $lh 0;
        }

        &.small {
            left: 50%;
            @include transform(translateX(-50%));

            width: 50%;
            max-width: 75rem;
            overflow: hidden;
            overflow-y: auto;
        }
    }

    @media print, screen and (max-width: 1200px) {
        margin-left: 0;
        border: 0;
        border-radius: 0;

        width: auto;
        max-width: 900px;
        height: auto;
        min-height: auto;

        .reveal.tiny {
            width: 30%;
            max-width: 600px;
        }
        .reveal.small {
            width: 50%;
            max-width: 75rem;
        }
        .reveal.large {
            top: 0;
            left: 0;
            min-width: $width-summary-dialog;
            height: 100%;

        }
        .reveal.full {
            top: 0;
            left: 0;

            margin-left: 0;
            border: 0;
            border-radius: 0;

            width: 100%;
            max-width: none;
            height: 100%;
            min-height: 100vh;
        }
    }

    .revealContent_wrapper {
        display: inline-block;
        min-width: $button-width*2 + $dialog-basis*1.5; // 312 = 150(buttons)*2 + 12(gutter)
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .custom_date_dialog {
            .datepicker-input {
                max-width: none;
            }
        }
    }

    .closeButton_wrapper {
        position: absolute;
        top: 22px;
        right: 22px;
        font-size: 18px;
        z-index: 31;

        .closeButton_container {
            font-size: 29px;
        }
    }
}


.reveal {
    & > :last-child {
        margin-bottom: 0;
    }
    .dialog_header {
        margin-bottom: $dialog-basis*9; // 36px
        .form_title {
            line-height: 1;
        }
    }

    .dialog_title {
        label {
            line-height: 1;
            color: $grey-dark;
        }
    }


    .dialog_footer {
        margin-top: $dialog-basis*6; // 48
    }

    .loader_wrapper {
        position: relative;
        min-width: $basis*26;
        min-height: $basis*18;
    }
}

.content_wrapper_mapping_unevaliable {
    min-width: 550px;
}

.eye-icon {
    position: absolute;
    top: 56%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    i {
        color: #546179;
    }
}
