
app-order-reports {
    display: none;
}
app-order-summary-dialog {
    .order-summary-dialog {
        .dialog-body {
            overflow-y: initial;
            max-height: initial;
        }
        .dialog-section-edit-options {
            display: none;
        }
        .dialog-footer {
            display: none;
        }
        table, .dialog-section {
            page-break-after: auto;
        }
    }
    .dialog-close-icon {
        display: none;
    }
}


