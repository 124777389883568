



.table-no-stripes {
	width: 100%;
    thead,
    tbody,
    tfoot {
        border: 1px solid $grey-alt;
        padding: 0;
    }

	thead {
		font-weight: 400;
		background-color: $grey-alt;
		color: $white;
	}
	th,
	td {
		padding: $basis/3 $basis; //4px 12px
        font-size: $fs; //14
		line-height: $lh;
	}

	.emptyTable {
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $grey-alt;
    }
}





