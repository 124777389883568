
// Font Awesome icons can't stand alone
// They have to be placed inside of container

.icon_container {
    display: inline-block;
    font-size: $icon-h; //16px
    line-height: 1;
}


.icon_button {
    display: inline-block;
    padding: $basis/4 0;
    vertical-align: middle;

    color  : $black;
    font-size: $fs;

    &.error {
        padding: 0;
        vertical-align: unset;
        font-size: $fs-large;
        color: $red-light;
        &:hover {
            color: $red-dark;
        }
    }

    .iconButtonLabel {
        display: inline-block;
        padding-left: $basis/2;

        font-size: $fs;
        line-height: $lh;
    }

    &:hover {
        color: $blue-dark;
        .iconButtonLabel {
            color: $blue-dark;
        }
        .icon_container {
            color: $blue-dark;
            .fa {
                color: $blue-dark;
            }
            .hoverIcon{
                display: inline-block;
            }
            .defaultIcon {
                display: none;
            }
        }

    }

    .icon_container {
        display: inline-block;
        font-size: $fs-large;

        .fa {
            line-height: $lh;
            color      : $grey;
            font-size  : 18px;
        }
        img {
            display: inline-block;
            height : 100%;
            width  : auto;
        }
        .hoverIcon {
            display: none;
        }
    }
}

.closeButton_container {
    position: absolute;
    right: 0;
    &:hover {
        .closeButton {
            color: $black-dark;
        }
    }
    .closeButton {
        display  : block;
        color    : $grey-dark;
        line-height: $basis*2;
        font-size: $fs-large; //16px; - fa has padding; real icon size: 14px
    }
}

a.sortIcon_container {
    padding-right: 14px;
    color: $grey-dark;
    &:last-child {
        padding-right: 0;
    }
    &:hover {
        color : $green;
    }
}
.image-delete-icon, .image-upload-icon {
    display: flex;
    cursor: pointer;
}
