

// Width & Height
$login-form-width: $basis*28; //336

$header-height : $lh*2.5;

$bottom-height: $lh*5;

$nav-tab-max-width: 200px;

$sidebar-width: 240px;

$width-summary-dialog: 950px;

$button-width: $basis*12.5;// 150px

$dropdown-width: $basis*12.5; // 150px

$standard-page-width: 1050px;

$icon-h: 16px;

$nav-custom-padding: 40px;

// Media queryies
$media-screen-l: 1440px;
$media-screen-m: 1200px;


@media screen and (max-width: $media-screen-m) {
    $sidebar-width: $lh*10
}
