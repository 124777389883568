.tab-header-item {
    min-width: 160px;
    height: 45px;
    cursor: pointer;
    margin-right: 8px;
    p {
        font-size: 14px;
        color: $black-dark;
        line-height: 38px;
        text-align: center;
        padding-bottom: 3px;
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: transparent;
        position: absolute;
        bottom: -1px
    }
    &.selected::after {
        background: #40B1D1;
    }
    &:last-child {
        margin-right: 0;
    }
}

.mat-tab-analytics {
    & .apexcharts-canvas {
        margin-top: 10px;
    }
    & .datepicker_container{
        justify-content: left !important;
        margin-left: -25px;
        & .date-picker-icons {
            position: relative;
            right: 25px;
        }
        & .datepicker-input {
            max-width: 400px;
        }
    }
}

.mat-tab-config {
    & .mat-tab-button {
        width: auto;
        margin-right:10px;
        margin-top: 10px;
    }
    & .mat-tab-body-content {
        padding: 10px;
        overflow: hidden;
    }
    & .mat-tab-body {
        min-height: 500px;
    }
    & .mat-tab-label {
        border: 1px solid #343d4e !important;
    }
    & .mat-tab-label-active {
        background-color: #343d4e;
        opacity: 0.8 !important;;
        color: white;
    }
    &.mat-tab-group {
        &.mat-primary {
            & .mat-ink-bar {
                height: unset;
            }
        }
    }
    &.mat-tab-nav-bar {
        &.mat-primary {
            & .mat-ink-bar {
                height: unset;
            }
        }
    }
}

.mat-tab-header {
    border-bottom: none;
    margin-left: 11px;
}

.mat-tab-button-danger {
    background-color:  red !important;
    border: red;
}

.box-padding {
    padding-top: 20px;
}

.font {
    font-size: 14pt;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
}

.font-danger {
    color: #E45B75;
}

.page-box-tools {
    border: 1px solid #cdcdcc;
    padding: 10px;
}

.page-box {
    border: 1px solid #cdcdcc;
    padding: 10px;
}

.page-box:hover {
    transition: 0.5s all ease-in-out;
    transform: scale(1.05);
    cursor: pointer
}
