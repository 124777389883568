
.filterIcon,
.fieldIcon {
    position: relative;
    width: $icon-h;
    height: $icon-h;

    @include border-radius(50px);
    vertical-align: middle;
    background: $blue-dark;
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));

        display: inline-block;
        font-size: 11px;
        line-height: 1;
        font-weight: 700;
        color: white;
    }
    &.disabled {
        background-color: rgba($blue-dark, 0.45);
    }
}
.filterIcon:after {
    content: 'F';
}
.fieldIcon:after {
    content: 'D';
    left: 54%;


    // Only for Firefox
    @supports (-moz-appearance:none) {
        left: 50%;
    }
}
