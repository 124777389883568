// Scrollbar :: BEGIN
.simplebar-track{
    &.vertical{
        display: none;
    }
    &.horizontal {
        background-color: #f2f2f2;

        .simplebar-scrollbar {
            height: 10px;
        }

    }
}
.simplebar-scroll-content {
    overflow-y: hidden;
}// Scrollbar :: END




[data-simplebar-horizontal] {
    .simplebar-track {
        &.vertical {
            display: none;
        }
    }
}

[data-simplebar-vertical] {
    .simplebar-track {
        &.horizontal {
            display: none;
        }
    }
}
