.ticket-type-revenue-report-page {

    .dropdown_container {
        min-width: 210px;
    }

    .datepicker-input {
        min-width: 210px;
    }

    .mainContent_wrapper {
        max-width: unset;
    }

    .row-padding {
        padding-left: round($basis*1.33);
        padding-right: round($basis*1.33);
    }

    .display-inline-block {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
            margin-right: 0px;
        }
    }

    .selectedFiltersArea_wrapper {
        position: relative;
        z-index: 19;
        min-height: 1px;
        white-space: nowrap;

        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        background-color: $selected-filters-area;

        .row-padding {
            margin-bottom: $basis;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .switch-column {
            padding-top: 20px;
            flex-shrink: 0;
        }
    }

    .selectedFiltersArea {
        padding: 23px 0;
        overflow: visible;
    }

    .buttons_wrapper {
        margin: $basis*4 $basis*2 0 $basis*2;
    }
}