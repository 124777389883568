.app-order-qsb-selection-wrapper {
    width: 100%;
}

.app-order-qsb-selection {
    .qsb-type-wrappers {
        height: 748px;
    }

    .qsb-label {
        margin-left: 10px;
    }

    .qsb-wrapper {
        height: 44px;
        border: 1px solid #D0DCEF;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &:hover {
            .qsb-bar {
                width: calc(100% + 1px);
            }

            .qsb-text {
                color: rgba(255, 255, 255, 1);
                font-weight: 600;
                letter-spacing: 0.0125em;
                text-shadow: -1px -1px 0 rgba(10, 10, 10, 0.5), 1px -1px 0 rgba(10, 10, 10, 0.5), -1px 1px 0 rgba(10, 10, 10, 0.5), 1px 1px 0 rgba(10, 10, 10, 0.5);

            }

            .qsb-x-icon {
                color: rgba(255, 255, 255, 0.5);
            }

            .qsb-forward-icon {
                color: rgba(255, 255, 255, 0.5);
            }

            .qsb-forward-icon-sales {
                color: #5c6371
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .qsb-placeholder-background {
        background: #5c6371
    }

    .qsb-bar {
        width: 8px;
        height: inherit;
        background-color: green;
        position: absolute;
        z-index: 0;
        left: -1px;
        transition: width;
    }

    .qsb-text {
        padding-left: 24px;
        color: $black-dark;
        position: absolute;
        font-size: 16px;
    }

    .qsb-placeholder-text {
        padding-left: 24px;
        color: $white;
        position: absolute;
        font-size: 16px;
    }

    .qsb-forward-icon {
        position: absolute;
        margin: auto;
        right: 16px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 10px;
    }

    .qsb-forward-icon-sales {
        position: absolute;
        margin: auto;
        right: 16px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 10px;
    }

    .qsb-x-icon {
        position: absolute;
        margin: auto;
        right: 16px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
    }

    .qsb-header {
        position: sticky;
        top: 0;
        z-index: 20;
        background-color: $white;
        padding-left: 20px;
        margin-left: -20px;
        margin-right: -20px;
        padding-right: 20px;
    }

}

.app-order-qsb-content {
    .app-order-qsb {
        app-order-info {
            height: 284px;

            >div {
                height: 268px;
            }
        }

        app-custom-fields {
            >div {
                margin-bottom: 0;
                height: 464px;
            }
        }

        app-payment-methods {
            .adjust-height {
                height: 202px;

            }
        }

    }

    .app-qsb-custom-fields-content {
        height: 529px;
    }

    .app-tiers-selector-content {
        max-height: 180px;
    }

    .useTouchScreenLayout app-tiers-selector-content {
        max-height: 220px;
    }

    .app-qsb-payment-method.expand-height {
        height: 445px;
    }

    .app-qsb-order-info-content,
    app-qsb-order-info-content {
        height: 293px;
    }

    .empty-state-qsb-selection {
        width: 100%;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 170px;

        svg {
            margin-bottom: 8px;
        }

        h2 {
            color: #7B88A3;
        }

        p {
            color: #ABBAD1;
        }
    }

    .error {
        color: red !important;
    }
}

.selected-sale-group {
    background-color: #5c6371;
    color: white;
}
