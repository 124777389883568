app-locations-and-location-lists-content {
    .locations-and-location-lists-content {
        height: calc(100vh - 143px);
    }

    .tab-content {
        min-height: calc(100% - 45px);
        width: 100%;
        max-height: calc(100vh - 188px);
    }
}

.locationListPrimaryButton {
    display: none;
}

.yes_no_content_wrapper {
    white-space: pre;
    min-height: 105px;
}

.deactiveWarning {
    color:red;
}

.edit-location-dialog {
    .button {
        width: initial;
        min-width: 180px;
    }

    .form_container {
        min-width: 510px;
    }

    .component-location-map-wrapper {
        min-width: 373px;
        height: 220px;
        width: 100%;
        border: 1px solid #ccc;
    }

    .component-input-wrapper {
        margin-bottom: 8px;
        input {
            width: 100%;
        }
    }
}

.location-list-items-wrapper {
    max-height: calc(100vh - 64px - 64px);

    .overflow {
        max-height: calc(100vh - 463px);
    }
}

app-locations-and-location-lists {
    .location-list-items-tooltip-wrapper{
        position: fixed;
        width: 390px;
        background-color: $white;
        padding: 16px;
        top: 38px;
        right: 138px;
        z-index: 10;
        box-shadow: 0 0 6px 1px #d8d8d8;

        p {
            min-height: 32px;
        }

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            display: block;
            right: -16px;
            border-right: transparent solid 8px;
            border-bottom: transparent solid 8px;
            border-top: transparent solid 8px;
            border-left: white solid 8px;
            -webkit-filter: drop-shadow(0 3px 4px #d8d8d8);
            filter: drop-shadow(4px 0px 3px #d8d8d8);
            z-index: 0;
        }

        &.arrow-top {
            &:after {
                top: 8px;
            }
        }

        &.arrow-bottom {
            &:after {
                bottom: 8px;
            }
        }
    }

    .location-list-items-tooltip-header {
        position: relative;

        p {
            font-weight: bold;
        }
    }

    .location-list-items-tooltip-row, .location-list-items-tooltip-header {
        p:nth-child(1) {
            width: 60%;
        }

        p:nth-child(2) {
            width: calc(20% - 24px);
        }

        p:nth-child(3) {
            width: 20%;
        }
    }
}

app-edit-location-list-form {
    display: block;
    width: 586px;

    input[type="text"] {
        width: 100%;
    }

    .inputLabelGroup {
        .dropdown_container {
            max-width: initial;
        }
    }

    .icon_container {
        cursor: pointer;
    }

    .closeButton {
        text-align: center;

        .svg-inline--fa.fa-times {
            width: 10px;
        }
    }

    .form-edit-location-list-item {
        .location-grid-items {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            &.center {
                justify-content: center;
            }
        }

        .location-grid-items-label {
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 320px;
        }

        label, .svg-inline--fa.fa-times, .icon_container {
            &:hover {
                color: $blue;
            }
        }
    }
}
