.pt-4px {
    padding-top: 4px;
}
.pb-4px {
    padding-bottom: 4px;
}

.pt-8px {
    padding-top: 8px;
}
.pr-8px {
    padding-right: 8px;
}
.pb-8px {
    padding-bottom: 8px;
}

.p-16px {
    padding: 16px;
}
.pt-16px {
    padding-top: 16px;
}
.pr-16px {
    padding-right: 16px;
}
.pb-16px {
    padding-bottom: 16px;
}
.pl-16px {
    padding-left: 16px;
}

.pt-24px {
    padding-top: 24px;
}
.pr-24px {
    padding-right: 24px;
}
.pl-24px {
    padding-left: 24px;
}
.p-9px {
    padding: 9px;
}

.p-9px-important {
    padding: 9px !important;
}

.p-0px-important {
    padding: 0px !important;
}

.pr-0px-important {
    padding: 0px !important;
}

.pl-20 {
    padding-left: 20px;
}

.p-10 {
    padding: 10px;
}
.pr-10px {
    padding-right: 10px;
}

.pb-0 {
    padding-bottom: 0px !important;
}
