
// Accordion :: BEGIN
.accordion-item {
    position: relative;
    &:first-child {
        & > :first-child {
            border-radius: 0 0 0 0;
        }
    }
    &:last-child {
        & > :last-child {
            border-radius: 0 0 0 0;
        }
    }
}
.accordion {
    margin-left: 0;
    list-style-type: none;
    &[disabled] {
        .accordion-title {
            cursor: not-allowed;
        }
    }
}



.accordion-title {
    @extend .row, .expanded !optional;
    position: relative;
    border: 0;
    border-bottom: 1px solid $black-light;

    font-size  : $fs;
    line-height: $lh;
    color      : $grey-dark;

    &:hover,
    &:focus {
        color: $grey-dark;
        background-color: $grey-light;
        &::before,
        &::after {
            color: $black;
        }
    }


    .is-active > &::before,
    .is-active > &::after {
        content: '\f0d7';
    }


    &::before,
    &::after {
        content: '\f0da';
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        display: inline-block;
        padding: 0;

        color: $grey-dark;
        font-size  : $fs;
        font-family: 'Font Awesome 5 Pro';
        font-style : normal;
        font-weight: normal;
        text-decoration: inherit;
    }
    &::before {
        left: $lh;
        right: auto;
        margin-top: 0;
        @media screen and (max-width: 1200px){
            left: $lh/1.5
        }
    }
    &::after  {
        right: $lh;
        display: none;
        @media screen and (max-width: 1200px){
            right: $lh/1.5
        }
    }
    .accordion-title-content {
        padding: 0 $lh;
    }

} // Accorion Title



.accordion-content {
    z-index: 10;
    display: none;

    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0;

    border: 0;
    border-bottom: 1px solid $black-light;
    background-color: $white;
    color: $black-dark;
}

:last-child > .accordion-content:last-child {
    border-bottom: 1px solid $black-light;
    @include box-shadow(0px 2px 5px 0px #ccc)
}// Accorion :: END






.accordion {
    &.bordered {
        border-bottom: 1px solid $black-light;

        &.empty {
            color: $empty-font;
            .card_title {
                border-bottom-width: 1px;
                color: $empty-font;
            }
        } // Empty :: end

        .card_title {
            border: 1px solid $black-light;
            border-bottom-width: 0;
            &:hover {
            }
        }

        .card-active {
            .card_content {
                border: 1px solid $black-light;
                border-bottom-width: 0;
            }
        }
    }


    .accordion_card {
        &.card-active {
            .card_title {
                &.has-icon:before {
                    content: "\f0d7";
                }
            }

            .card_content {
                max-height: 5000px;
            }
        }


        // Accordion Card Title
        .card_title {
            position: relative; //for the up-down carret
            display: block;
            padding: 14px $lh;
            color: $black-dark;


            //Up down carrot icon :: BEGIN
            &.has-icon {
                padding-left: $lh*2;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    @include transform(translateY(-50%));

                    width: $lh*2;
                    text-align: center;
                    content: "\f0da";
                    font-family: 'Font Awesome 5 Pro';
                    font-size: $fs-xl;
                    line-height: 1;
                    color: $black;
                }
            }//Up down carrot icon :: END

            .cardTitle_label {
                display: inline-block;
            }
        }// Accordion Card Title





        // Accordion Card Content
        .card_content {
            max-height: 0;
            overflow: hidden;
            border: 0px solid $grey-dark;

            .cardContent_container {
                padding: $lh*1.3 $lh*1.5;
            }
        } // Accordion Card Content
    }
}
