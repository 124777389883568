.void-order-dialog {
    label {
        color: #546179;
        line-height: 1.25;
    }

    label.error-msg {
        margin-left: 171px;
        line-height: 40px;
        color: #E45B75;
    }

    .dialog-header {
        padding: 24px;
    }

    .dialog-content {
        width: 628px;
        height: 500px;
        top: 24px;
    }

    .dialog-body {
        padding: 0 24px 24px 24px;
        height: 360px;
    }

    .dialog-checkboxes-wrapper {
        margin-left: 171px;
    }

    .summary-general-info-wrapper {
        margin-bottom: 20px;
        border-bottom: 1px solid #ececec;
        padding: 0 10px 10px 10px;
        max-height: 120px;
        overflow-y: auto;

        p:first-child {
            min-width: initial;
        }

        .summary-data-wrapper {
            align-items: center;
        }
    }

    app-checkbox {
        height: 25px;
        display: block;
    }

    table {
        margin-bottom: 12px;
    }

    .checkboxLabelGroup [type="checkbox"]:disabled + label, .checkboxLabelGroup [type="checkbox"].disabled + label {
        color: $black-dark;
        opacity: 1;
    }

    app-input-label-group {
        .inputLabelGroup {
            display: flex;
            height: 40px;
            align-items: center;

            div:first-child {
                flex-basis: 171px;
            }

            div:last-child {
                flex-basis: calc(100% - 141px);

                input, app-dropdown {
                    width: 100%;
                    max-width: 320px;
                }
            }
        }
    }

    .summary-payment-data-label-wrapper {
        flex-basis: 171px;

        label {
            align-self: flex-start;
        }
    }

}

.removeIcon {
    display: none !important;
}

.showIcon {
    display: inline-block !important;
}

.search-loader-icon-wrapper {
    display: inline-flex;
    align-items: center;
    padding: 13px 10px;
    height: 50px;

    .iconImage_container {
        height: 20px;
        width: 20px;
        color: rgba(21, 25, 35, 0.8);
    }
}

.summary-data-wrapper {
    input, app-dropdown {
        flex-basis: calc(100% - 141px);
    }

    .date-picker {
        input {
            flex-basis: auto;
        }
    }
}

