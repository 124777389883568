
// make times icon square
.fa-times {
    &.svg-inline--fa {
        width: 1em;
        height: 1em;
    }
}


// Up/Down Arrows - Accordion dropoff location header
.fa-arrow-square-down,
.fa-arrow-square-up {
    &.svg-inline--fa {
        width: 1em;
        height: 1em;
    }
}
// Table
.fa-edit {
    &.svg-inline--fa {
        width: 1.1em;
    }
}

.fa-plus-circle, .fa-minus-circle {
    cursor: pointer;
}
