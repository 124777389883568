
.search_container {
    position: relative;
    input {
        padding-right: $basis*2;
        font-family: 'Font Awesome 5 Pro', $ff_sans-pro;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font-family: 'Font Awesome 5 Pro', $ff_sans-pro;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            font-family: 'Font Awesome 5 Pro', $ff_sans-pro;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            font-family: 'Font Awesome 5 Pro', $ff_sans-pro;
        }
        &:-moz-placeholder { /* Firefox 18- */
            font-family: 'Font Awesome 5 Pro', $ff_sans-pro;
        }
    }

    .closeButton_container {
        background-color: $white;
        right: $basis/2;
        top: 50%;
        @include transform(translateY(-50%));
    }
}
