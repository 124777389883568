app-occurrence-management {
    .page-wrapper {
        position: initial;
        min-height: 100vh;
    }

    .pageContent_wrapper {
        overflow-y: initial;
        position: initial;
    }

    .full-width-layout .pageContent_wrapper .mainContent_wrapper {
        position: initial;
        padding-bottom: 0;
    }

    .line-height-22px {
        line-height: 22px;
    }

    .height-22px {
        height: 22px;
    }

    .empty-state {
        padding-top: 80px;

        h2 {
            font-size: 22px;
            line-height: 28px;
            color: rgba(123, 136, 163, 1);
        }

        p {
            font-size: 16px;
            line-height: 18px;
            color: rgba(123, 136, 163, 0.75);
        }

    }

    .filter-occurrences-btn {
        cursor: pointer;

        svg path {
            fill: #3D495F;
        }

        span {
            color: #3D495F;
        }

        &:hover, &.selected {
            svg path {
                fill: #40B1D1;
            }

            span {
                color: #40B1D1;
            }
        }
    }

    .filter-occurrences-sidebar {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 290px;
        top: 50px;
        height: calc(100vh - 50px);
        background-color: #D0DCEF;
        z-index: 3;
        transition: transform ease 0.4s;
        will-change: transform;

        .close-icon {
            align-self: flex-end;
            cursor: pointer;

            path {
                fill: #546179;
            }

            &:hover {
                path {
                    fill: #000000;
                }
            }
        }
        .md-drppicker {
            position: fixed !important;
        }
    }

    .filter-action-buttons-wrapper {
        .button {
            font-size: 14px;
            height: 36px;
            line-height: 100%;
            min-width: calc(50% - 8px);

            &.ghost-button {
                margin-right: 8px;
            }
        }
    }

    .filter-option {
        .label {
            margin-bottom: 4px;
        }
    }

    .filter-wrapper {
        height: calc(100% - 12px - 32px - 16px);
    }

    .occurrence-management-header-actions {
        align-items: end;
        justify-content: space-between;

        .filter-tags-wrapper {
            color: $black-dark;
            flex-wrap: wrap;
        }

        .filter-tag {
            background-color: #e6e6e6;
            padding: 2px 12px;
            border-radius: 24px;
            cursor: pointer;
            line-height: initial;
            transition: 0.1s background-color, 0.1s color;

            &:hover, &.selected {
                background-color: #40B1D1;
                color: $white;
            }
        }
    }

    .animate-out {
        transform: translateX(100%);
    }

    .animate-in {
        transform: translateX(0);
    }

    .table-action {
        cursor: pointer;
        height: 32px;
        padding: 0 16px;
        line-height: 32px;

        &:hover {
            background: rgba(204, 204, 204, 0.2);
            font-weight: 600;
        }
    }

    .show-table-actions {
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 8px;
    }

    .show-table-actions.selected {
        cursor: pointer;

        svg circle {
            fill: #40B1D1;
        }
    }

    .table-actions-dropdown-wrapper {
        position: absolute;
        top: 24px;
        z-index: 10;
        left: 0;
        background-color: $white;
        width: 205px;
        box-shadow: 0 3px 5px rgba(84, 97, 121, 0.3);
        border: 1px solid #D0DCEF
    }

    .focused {
        .dropdownHeader_wrapper {
            border: 1px solid $blue;
        }
    }

    app-datepicker-old.focused .datepicker_container input {
        border: 1px solid $blue;
    }
}

app-occurrences-table {
    .table-of-occurrences {
        max-height: calc(100vh - 50px - 70px - 64px - 16px);
        overflow-y: auto;
        display: block;
        @extend .overflow;

        .table_container {
            margin-bottom: 0;
        }

        .table_stripped, thead {
            border-top: 0;
        }

        th {
            position: -webkit-sticky;
            position: sticky;
            top: -1px;
            background-color: #798aa3;
            z-index: 2;
        }
    }
}

.edit-occurrences-dialog, .deactivate-occurrences-dialog {
    .payment-summary-table {
        margin: 0 auto 27px auto;
        padding: 0;
        max-height: calc(100vh - 450px);
        overflow-y: scroll;

        ul {
            padding-bottom: 16px;
        }

        h3 {
            padding: 8px 16px;
        }

        li {
            margin-bottom: 0;

            a {
                color: $black-dark;

                &:hover {
                    color: #40B1D1;
                    text-decoration: underline;
                }
            }
        }
    }

    .content_wrapper {
        min-width: 520px;
        min-height: 128px;
    }

    .footer_wrapper {
        padding-bottom: 0;

        button {
            min-width: 150px;
            width: initial;
        }
    }
}

.occurrence-manifest-dialog {
    .reveal {
        max-width: 100vw;
        padding: 0;
    }

    .header_wrapper {
        padding: $fs-small * 2;
    }

    .content_wrapper {
        padding: 0 $fs-small * 2;
    }

    .footer_wrapper {
        border-top: 1px solid $grey;
        display: flex;
        padding: 10px;

        .ghost-button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .occurrence-manifest-table {
        table {
            tbody {
                border: medium none;

                th {
                    font-weight: 600;
                }

                tr {
                    .fa-file-alt {
                        cursor: pointer;
                        &:hover {
                            color: $blue;
                        }
                    }
                }

                td:first-child {
                    padding: 8px 16px 8px 0;
                }

                td:last-child {
                    padding: 8px 0 8px 16px;
                }

                .separator {
                    td {
                        padding: 0;
                    }

                    td p {
                        height: 1px;
                        margin: 10px 0;
                        background-color: $grey;
                    }
                }
            }
        }
    }

    .empty-state {
        width: 100%;
        min-height: 216px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            color: #ABBAD1;
        }
    }
}
