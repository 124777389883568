


// Header Toolbox
.toolboxButtons_container {

    vertical-align: middle;

    .toolboxButtons_list {
        white-space: nowrap;
        .toolboxButtons_li {
            display: inline-block;
            vertical-align: middle;
            padding-right: $lh;

            &:last-child {
                padding-right: 0;
            }

        }
    }
}

.resetReport_button {
    display: inline-block;
    padding: $lh/4 0;
    color: $grey-dark;
    &:hover {
        color: $blue-dark;
    }
}

