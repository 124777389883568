// Custom Report Table
.table_striped {
    width: 100%;
    border: 1px solid $grey-alt;

    thead, tbody, tfoot {
        border: unset;
    }

    thead {
        background-color: $grey-alt;
        color: $white;
        th, td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }
    }

   th, td {
        padding: $basis/1.5 $basis*1.5; //8px 18px
        white-space: nowrap;
        border-right: 1px solid $grey;
        &:last-child {
            border: unset;
        }
    }

    tr {
        &:hover {
            color: $blue;
        }
        &.tr-active {
            background-color: $blue-light !important;
            color: white;
            a {
                color: white;
            }
        }
        &:nth-child(even) {
            background-color: $grey-light;
            border-bottom: 0;
        }
    }

    // Table sub-header (e.g. summable fields)
    .tr_highlighted {
        &:hover {
            color: white;
        }
    }
    .tr-active {
        .tableIconContainer {
            color: white;
        }
    }// Cell with Icon :: END
    .tableHeaderColumn {
        .tableHeaderColumnLink_container {
            display: block;
            min-width: $lh*4;
            cursor: default;

            &.tableHeaderSortable {
                cursor: pointer;
                .sortIcon {
                    display: inline-block;
                }
            }

            &:hover {
                .tableHeaderColumnSortIcon,
                .tableHeaderColumnLabel {
                    color: $white;
                }
                .defaultSortIcon {
                    .sort-default {
                        display: inline-block !important;
                    }
                }
            }
            .tableHeaderColumnLabel {
                position: relative;
                padding-right: $lh;

                display: inline-block;
                color: $white;
            }

            .sortIcon {
                position: absolute;
                right: 0;

                display: none;
                color: $white;
                span {
                    display: none;
                }
                &.OnChanges {
                    .sort-default {
                        display: none;
                    }
                }
                &.sortAscending {
                    .sort-up {
                        display: inline-block;
                    }
                }
                &.sortDescending {
                    .sort-down {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.table_striped_parent {
    width: 100%;
    border: 1px solid $grey-alt;

    thead, tbody, tfoot {
        border: unset;
    }

    thead {
        background-color: $grey-alt;
        color: $white;
        th, td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }
    }

        tr {
            &:hover {
                color: $blue;
            }

            &.tr-active {
                background-color: $blue-light !important;
                color: white;
                a {
                    color: white;
                }
            }
        }

        th,
        td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }

    tr {
        &:nth-child(4n-1) {
            background-color: $grey-light;
            border-bottom: 0;
        }
    }

    // Table sub-header (e.g. summable fields)
    .tr_highlighted {
        &:hover {
            color: white;
        }
    }
    .tr-active {
        .tableIconContainer {
            color: white;
        }
    }// Cell with Icon :: END
}

.table_striped_child {
    width: 100%;
    border: 1px solid $grey-alt;

    thead, tbody, tfoot {
        border: unset;
    }

    thead {
        background-color: $grey-alt;
        color: $white;
        th, td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }
    }

        tr {
            &:hover {
                color: $blue;
            }

            &.tr-active {
                background-color: $blue-light !important;
                color: white;
                a {
                    color: white;
                }
            }
        }

        th,
        td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }

    tr {
        &:nth-child(even) {
            background-color: $grey-light;
            border-bottom: 0;
        }
        &:nth-child(4n-1) {
            background-color: transparent;
        }
    }

    // Table sub-header (e.g. summable fields)
    .tr_highlighted {
        &:hover {
            color: white;
        }
    }
    .tr-active {
        .tableIconContainer {
            color: white;
        }
    }// Cell with Icon :: END
}

.table_striped_child_one_color {
    width: 100%;
    border: 1px solid $grey-alt;

    thead, tbody, tfoot {
        border: unset;
    }

    thead {
        background-color: $grey-alt;
        color: $white;
        th, td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }
    }

        tr {
            &:hover {
                color: $blue;
            }

            &.tr-active {
                background-color: $blue-light !important;
                color: white;
                a {
                    color: white;
                }
            }
        }

        th,
        td {
            padding: $basis/1.5 $basis*1.5; //8px 18px
            white-space: nowrap;
            border-right: 1px solid $grey;
            &:last-child {
                border: unset;
            }
        }

    tr {
        &:nth-child(even) {
            background-color: transparent;;
            border-bottom: 0;
        }
    }

    // Table sub-header (e.g. summable fields)
    .tr_highlighted {
        &:hover {
            color: white;
        }
    }
    .tr-active {
        .tableIconContainer {
            color: white;
        }
    }// Cell with Icon :: END
}