
.selectedFieldsArea {

    .selectedFieldsList {
        margin: 0 (-$basis/2);
        li {
            display: inline-block;
            margin: 0 $basis/2;
            vertical-align: middle;
        }
        .dropArea {
            vertical-align: middle;
        }
    }

    .dragNdropCard {
        position: relative;
        max-width: $basis*14; //168px;
        height: 100%;
        .dragNdropCardHeader {
            line-height: $basis*3;
            vertical-align: middle;

            .dragNdropCardLabel {
                line-height: $fs*1.2858; //18px
                vertical-align: middle;
            }
        }
    }
}
