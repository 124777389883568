$login-basis: 8px;
$login-content-min-h: 110px;

app-login-form, app-login-location {
    display: block;
    width: 100%;
    .inputLabelGroup .label {
        margin-bottom: 4px;
    }
}

.loginPage_wrapper {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .brandSection_wrapper {
        @include  clearfix;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 100%;
        padding: $basis*5; //60px

        background-image: url('../../../images/login-img.jpg');
        background-size: cover;
    }
    .loginForm_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        //width: 396px;
        width: 520px;
        height: 100%;
        //padding: $basis*5; //60px
        padding: $basis; //12px
        background-color: $white;
    }
}
.brandSection_container {
    position: relative;
    width: 100%;
    height: 100%;

    .logoContainer {
        display:inline-block;
        height: $basis*2; //24px
        width: 164px;
        .logoIcon {
            width: 100%;
            display: block;
            margin-top: -5px;
        }
    }
    .loginPageSlogan_container {
        position: absolute;
        bottom: 0;
        p {
            line-height: 1.25;
            font-weight: 300;
            color: $white;
            font-size: 30px;

        }
    }
}
//Login Form
.loginForm_container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $white;

    display: flex;
    justify-content: center;
    flex-direction: column;

    .form_container {
        h1 {
            line-height: $login-basis*3.5;
        }
    }
}
