
/*Base Colors*/
$white   : #ffffff;
$lavander: rgba(160, 176, 240, 0.25);
$grey-alt: #798aa3;
$grey-ali-light: #9aa7ba;

// gray
$grey-light: #edf0f5;
$grey      : #d9e0ea;
$grey-dark : #546179;
$border    : #ABBAD1;



// black
$black-light: #3d495f;
$black      : #343d4e;
$black-dark : #151923;




/* Brand Colors */
// blue
$blue-alt     : #EAF0FA;
$blue-light   : #79c8de;
$blue         : #40B1D1;
$blue-dark    : #0e8db1;
$havelock-blue: #417bd1;

$primary-nav  : #e8ecfc;


// green
$green-light: #11dfd8;
$green      : #08cbbf;
$green-dark : #00aaa0;
$niagara    : #08A89E;

// RED
$red-light    : #ff849b;
$red          : #e45b75;
$red-dark     : #c53e57;
$burnt-sienna : #ED5050;

// Use Cases
$primary-color : $blue;
$empty-font    : #7b88a3;

    // Order Reports
    $selected-filters-area: #d0dcef;
    $selected-fields-area : $blue-alt;
    $OR-expression-group  : $blue-alt;
    $fields-info-message  : $blue-dark;


// Drag N Drop
    $drop-field-bg    : #8bd2e3;
    $drop-area-active : #b5e2ed;

// Notification alert colors:
$notification-alert-info    : $havelock-blue;
$notification-alert-success : $niagara;
$notification-alert-error   : $burnt-sienna;



