
//Standard page layout
//gutter: 12px
//max width: 1050px;
$basis: 12px;



// FONTS
$fs: 14px;
$lh: 20px; //20px
$ff_sans-pro: 'Source Sans Pro', sans-serif; // 300, 400, 600, 700


$fs-small: 12px;
$fs-large: 16px;
$fs-xl   : 18px;

$fs-h3: 22px;
$fs-slogan: $basis*3;

// Secondary Navigation - font
$fs-nav-sec-title: 20px;
$fs-nav-sec-li: 14px;







