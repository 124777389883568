li {
    ul {
        padding-left: 20px;
    }
    .tree-list-item {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            left: -16px;
            background-color: #D0DCEF;
        }
    }
    .tree-list-item:last-child {
        &:before {
            height: calc(100% - 16px);
        }
    }
}

.tree-list-item {
    line-height: 18px;
    color: #151923;
    .fa, .fab, .fad, .fal, .far, .fas{
        margin-right: 4px;
        width: 16px;
        color: #40B1D1;
        cursor: pointer;
        line-height: 32px;
    }
    div {
        cursor: pointer;
        line-height: 32px;
    }
    app-checkbox, app-permission-checkbox {
        display: inline-flex;
        min-height: 32px;
        align-items: center;
    }
    .user-inheritance-indicator {
        cursor: default;
        margin: 0 0 0 8px;
        i {
            color: #C1C8DB;
            cursor: inherit;
            font-size: 10px;
            background-color: #EFF2FC;
            width: 16px;
            height: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
        }
    }
    .user-inherited-permissions {
        align-items: baseline;
    }
    .user-inheritance-action-button {
        font-style: italic;
        font-size: 12px;
        color: #40B1D1;
        margin: 0 0 0 8px;
        &:hover {
            text-decoration: underline;
        }
    }
}
.tree-branch {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: -16px;
        top: 16px;
        margin: auto;
        width: 8px;
        height: 1px;
        background-color: #D0DCEF;
    }
}

div.selected {
    font-weight: 800;
}

.app-checkbox-wrapper {
    position: sticky;
    padding: 16px 0;
    background: #fff;
    bottom: 0;
    z-index: 2;
}

.inactive {
    color: rgba(21, 25, 35, 0.5);
}

.tree-list-item-last-child {
    color: #40B1D1;
    &:hover {
        text-decoration: underline;
    }
    div i {
        visibility: hidden;
    }
    .node-data-label {
        &:hover {
            text-decoration: underline;
        }
    }
}
