.selectedFiltersArea {
    padding: ($basis*2 - 1) 0; // reduced for border
    overflow-x: auto;
}


.expressionItemsList {
    margin: 0 (-$basis/2);
    margin-top: $basis/2.4;

    .datepicker_container {
        position: fixed !important;
        z-index: 10;
    }

    .expressionItem {
        margin: 0 $basis/2;
    }

    .dropArea {
        vertical-align: middle;
    }

    .expressionItem {
        display: inline-block;
        vertical-align: middle;

        &.hidden {
            display: inline-block;
            width: 0;
            height: 0;
            padding: 0;
        }
    }
}


.expressionFilter {
    .dragNdropCardHeader {
        margin-bottom: $basis;
        line-height: $basis*2;
    }
}


.expressionGroup {
    position: relative;
    min-height: $basis*10;
    min-width: $basis*20;
    background-color: $OR-expression-group;

    > .dragNdropCardHeader {
        min-height: $basis*2;
        margin-bottom: $basis/2;
    }

    .expressionItem {
        margin: 0 $basis/2;

        .expressionItem {
            margin: 0 $basis/3;
        }
    }
}

