
select {
    height: auto;
    margin: 0;
    padding: $lh/4;
    padding-right: 1.5rem;
    border: 1px solid $black-dark;
    @include border-radius (0);
    @include appearance (none);

    line-height: $lh;

    font-family: $ff_sans-pro;
    font-size: $fs;
    font-weight: normal;

    color: $black-dark;
    background-color: #fff;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    @include background-origin (content-box);
    @include background-size (9px 6px);
    background-position: right -1rem center;
    background-repeat: no-repeat;

    -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
}

@media screen and (min-width: 0 \0
) {
    select {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
    }
}

select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    -webkit-box-shadow: 0 0 5px #cacaca;
    box-shadow: 0 0 5px #cacaca;
    -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
}

select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed;
}

select::-ms-expand {
    display: none;
}

select[multiple] {
    height: auto;
    background-image: none;
}
