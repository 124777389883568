.dropdown-tag-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &.scrollable-content {
        max-height: 250px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.35);
        }
    }
}
