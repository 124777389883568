/*
    IE11 doesn't support dragImage function,
    instead we use [dragTransitClass] property from ng-drag-n-drop library styling elements as dragImage
*/


// Support For IE11 - dragImage function 
.is-ie-drag {
    display: inline-block;
    background: $white !important;
    z-index: 20;
     & > * {
        visibility: visible !important; //override placeholder class
     }
    // Sidebar item
    &.reportItem {
        padding: $basis/3 $basis/2;
    }

    &.expressionFilter,
    &.expressionGroup {
        @include transform(scale(0.5));
    }
}