

.orderReportTable_container {

    .simplebar-track.simplebar-horizontal {
        pointer-events: auto;
    }

    .loader_wrapper {
        z-index: 10;
        position: absolute;
        left: 0;
        right: 0;
        background: $white;
    }

	.reportStatusMessage_container {
		.reportStatusMessage {
            color: $black-dark;
			line-height: $lh*1.5;
		}
	}





    .table_wrapper {
        width : 100%;
        padding: 0 $lh*2;
        //overflow: hidden;


        .table_caption {
            padding: 0 $lh;
        }

		.table_container {
            width: 100%;
            margin-bottom: 0;
            max-width: 100%;
            position: relative;
            //padding-bottom: 0!important;


            .td_content {
                display: block;
                max-width: 400px;
                overflow: hidden;
                text-overflow: ellipsis;

            }

		}
	}
    .tableHeaderColumn {
        .tableHeaderColumnLink_container {
            display: block;
            min-width: $lh*4;
            cursor: default;

            &.tableHeaderSortable {
                cursor: pointer;
                .sortIcon {
                    display: inline-block;
                }
            }

            &:hover {
                .tableHeaderColumnSortIcon,
                .tableHeaderColumnLabel {
                    color: $white;
                }
                .defaultSortIcon {
                    .sort-default {
                        display: inline-block !important;
                    }
                }
            }
            .tableHeaderColumnLabel {
                position: relative;
                padding-right: $lh;

                display: inline-block;
                color: $white;
            }

            .sortIcon {
                position: absolute;
                right: 0;

                display: none;
                color: $white;
                span {
                    display: none;
                }
                &.OnChanges {
                    .sort-default {
                        display: none;
                    }
                }
                &.sortAscending {
                    .sort-up {
                        display: inline-block;
                    }
                }
                &.sortDescending {
                    .sort-down {
                        display: inline-block;
                    }
                }
            }
        }
    }
}







