/* source-sans-pro-300 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v11-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
