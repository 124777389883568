.admin-panel {

    .status-indicator {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .margined-right {
        margin-right: 30px;
    }

    .margined-left {
        margin-left: 20px;
    }

    .health-check-container-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    .status-text {
        padding-bottom: 10px;

        &.heading {
            width: 300px;
            text-align: center;
        }
    }

    .collored-green {
        background-color: #379c67d6;
    }

    .collored-red {
        background-color: $red;
    }

    .status-container {
        width: 300px;
        display: inline-flex;
        align-items: center;

        &.heading {
            text-align: center;
        }
    }

    .row-padding-pie-charts {
        display: flex;
        justify-content: flex-start;
        padding-top: round($basis*1.33);
        padding-left: round($basis*1.33);
        padding-right: round($basis*1.33);
        flex-wrap: wrap;
        overflow: hidden;
    }

    .box-padding-top {
        padding-top: 30px;
    }

    .pie-chart-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .display-inline-block-pie-charts {
        display: inline-block;
        margin-right: 30px;
        justify-content: start;
        min-width: 400px;

        .container-pie-charts {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .loader-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    .line-chart-container {
        padding-top: 8px;
    }

    .container-box {
        border: 1px solid #cdcdcc;
        padding: 20px;

        h2 {
          text-align: center;
        }

        a {
          padding: 5px;
        }
      }

}

.clear-none {
    .clear {
      svg {
        display: none;
      }
    }

    .fal {
      display: none;
    }
}

.date-input-width input {
    width: 350px !important;
    max-width: 350px !important;
  }
