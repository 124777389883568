.dialog-new-template {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 30;
    height: 100vh;

    .dialog-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
    }

    .dialog-content {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 4;
        background-color: $white;
    }

    .dialog-body {
        padding: 16px;
    }

    .dialog-header {
        display: flex;
        align-content: center;
        justify-content: space-between;
        padding: 16px 16px 0 16px;

        h2 {
            padding: 8px
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        flex-direction: column;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            z-index: 5;
            height: 1px;
            background-color: red;
        }
    }
}

.purchase-remove-dialog {
    .dialog-content {
        height: 223px;
        width: 364px;
        top: 122px;
    }

    .dialog-body {
        padding: 8px;
        margin-bottom: 20px;
    }
}

.order-summary-dialog {
    .dialog-header {
        padding: 24px;
    }

    .dialog-content {
        width: 1100px;
        top: 24px;
        max-height: calc(100% - 48px);
        min-height: 350px;
    }

    .dialog-body {
        overflow-y: auto;
        padding: 0 24px 0 24px;
        max-height: calc(100vh - 240px);
    }

    .dialog-footer {
        border-top: 1px solid #ABBAD1;
        padding: 8px 15px;
    }

    .dialog-checkboxes-wrapper {
        display: flex;
        padding: 8px;
        margin: -8px;

        &:before {
            content: "";
        }
    }

    app-checkbox {
        margin: 16px 16px 24px 0;
    }

    .dialog-buttons-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .ghost-button, .button-solid-danger {
        padding: 0;
        height: 34px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-right: 8px;
            width: 14px;
        }
    }

    .ghost-button {
        color: #546179;
    }

    table {
        //table-layout:fixed;
        text-align: left;
        border-collapse: collapse;
        width: 100%;
    }

    th {
        font-size: 12px;
        color: #546179;
        padding: 0 8px;

        &:first-child {
            padding: 0 8px 0 0;
        }
    }

    td {
        font-size: 14px;
        vertical-align: top;
        line-height: 22px;
        padding: 0 8px;

        &:first-child {
            padding: 0 5px 0 0;
        }
    }

    table thead, table tbody, table tfoot {
        border: medium none;
    }

    .summary-payment-info-wrapper, .summary-pass-detail-wrapper {
        flex-direction: column;

        table {
            table-layout: fixed;
            margin-bottom: 16px;
        }

        .children-table-container {
            padding-left: 50px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background: $black;
                left: 20px;
            }

            table {
                border-collapse: collapse;
                table-layout: auto;

                tr {
                    th {
                        padding: 0 5px 0 0;
                    }

                    td {
                        padding: 5px 5px 5px 0;
                        border-bottom: 1px solid #e4e4e4;
                        position: relative;

                        &:first-child {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border: 1px solid $black;
                                border-radius: 50%;
                                left: -37px;
                                top: 50%;
                                background: $white;
                                transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                top: 50%;
                                left: -31px;
                                transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                                background: $black;
                            }
                        }
                    }
                }
            }
        }

        .table-wrapper {
            position: relative;
            .table-arrow {
                font-weight: unset;
                position: absolute;
                left: -15px;
                top: 2px;
                transition: 0.4s;

                &.down {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .width-1 {
        width: 140px;
    }

    .width-2 {
        width: 40px;
    }

    .min-width-1 {
        width: 150px;
    }

    .width-5px {
        width: 5px;
    }

    h1 {
        font-weight: 700;
    }

    h2 {
        font-weight: 700;
        margin-bottom: 17px;
        font-size: 18px;
        line-height: 24px;
    }

    h3 {
        font-weight: 700;
    }

    h4 {
        margin-top: 6px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: $black-dark;
    }

    .loader_wrapper {
        position: initial;
        z-index: initial;
    }

}

.related-order-td {
    width: 346px;
    max-width: 346px;
    word-wrap: break-word;
}

.related-orders {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.dialog-section-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    align-items: center;
}

.dialog-section {
    padding-bottom: 17px;
    margin-bottom: 15px;
    border-bottom: 1px solid $black-dark;

}

.dialog-section-edit-options {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-option {
        margin-right: 16px;
    }

    .delete-option, .edit-option {
        cursor: pointer;
        opacity: 0.85;

        &:hover {
            opacity: 1;
        }
    }

    i {
        color: #546179;
        font-size: 10px;
        margin-right: 8px;
    }

    span {
        font-size: 14px;
        color: #546179;
    }

    .fa-times-circle {
        color: #E45B75;
        font-size: 11px;
    }

}

.dialog-sub-section {
    margin-top: 16px;
}

.summary-general-info-wrapper {
    display: flex;
    flex-wrap: wrap;

    .summary-general-info {
        flex-grow: 1;
    }

    .summary-data-wrapper {
        display: flex;
    }

    p {
        line-height: 16px;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 400;

        span:first-child {
            min-width: 18px;
        }

        span {
            margin-right: 8px;
            display: inline-block
        }
    }

    p:first-child {
        color: #546179;
        max-width: 96px;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        margin-right: 16px;
    }

    .summary-data-counter {
        min-width: 20px;
        margin-right: 8px;
        display: inline-block
    }
}

.summary-scroll-wrapper {
    display: block;
    max-height: 300px !important;
    overflow-y: auto;
}

.summary-general-info-wrapper.editable {
    .h-38 {
        height: 38px;
    }

    .summary-data-wrapper {
        align-items: center;
    }

    p:first-child {
        flex-basis: 96px;
    }

    app-dropdown, app-datepicker {
        flex-basis: calc(100% - 96px - 32px);
    }

    .summary-general-button-wrapper {
        padding: 18px 18px 9px 18px;
        margin: auto;

        button {
            width: 100px;
            height: 32px;
            margin-right: 16px;
            padding: 0;
            font-size: 14px;
        }

        .cancel-button {
            border: 1px solid #546179;
            color: #546179;

            &:hover {
                background-color: #546179;
                color: $white;
            }
        }
    }
}

.summary-general-info-wrapper.no-show {
    position: absolute;
    width: 100%;
    height: calc(100% - 41px);
    background-color: rgba(255, 255, 255, 0.9);
    top: 41px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;

    p {
        font-size: 14px;
        color: $black-dark;

    }

    .summary-general-button-wrapper {
        padding: 18px 18px 9px 18px;

        button {
            width: 100px;
            height: 32px;
            margin-right: 16px;
            padding: 0;
            font-size: 14px;
        }

        .cancel-button {
            border: 1px solid #546179;
            color: #546179;

            &:hover {
                background-color: #546179;
                color: $white;
            }
        }
    }
}

.related-orders-present .dialog-section, .no-related-orders .dialog-section {
    border: medium none;
}

.summary-general-info {
    flex-grow: 1;
    min-width: 50%;
}

.dialog-close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    opacity: 0.8;
    font-size: 29px;

    &:hover {
        opacity: 1;
    }
}

.dialog-section-header + .summary-general-info-wrapper {
    margin-bottom: 0;
}

.dialog-section-header + .summary-general-info-wrapper:last-child {
    margin-bottom: 0;
}

.dialog-section-header + .summary-general-info-wrapper + .summary-general-info-wrapper {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgb(240, 240, 240);
}

.dialog-section-header + .summary-general-info-wrapper + .summary-general-info-wrapper:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    border-bottom: medium none;
}

.order-detail-wrapper {
    margin-bottom: 16px;
}

.summary-general-info-wrapper + .placeholderText-emptySection {
    padding: 8px 0 16px 0;
}

.order-summary-no-permission-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: calc((50% - 165px) / 2) 0;

    svg {
        width: 120px;
        margin-bottom: 20px;
    }

    h1, p {
        color: #7b88a3;
    }

    h1 {
        font-weight: 400;
    }
}

.payment-summary-table {
    margin: -20px auto 48px auto;
    padding: 0 40px;

    h3 {
        padding-left: 40px;
        border-left: 3px solid rgba(64, 177, 209, 0.67);
        padding-bottom: 16px;
    }

    ul {
        padding-left: 40px;
        border-left: 3px solid rgba(64, 177, 209, 0.67);
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        width: 25%;
        margin-bottom: 8px;
    }

    &::after {
        content: "";
        clear: both;
        display: block;
    }
}

.payment-summary-table-all-platforms {
    margin: 0px auto 48px auto;
    padding: 0 40px;

    h3 {
        padding-left: 40px;
        border-left: 3px solid rgba(64, 177, 209, 0.67);
        padding-bottom: 16px;
    }

    ul {
        padding-left: 40px;
        border-left: 3px solid rgba(64, 177, 209, 0.67);
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        width: 20%;
        margin-bottom: 8px;
        margin-top: 2px;
    }

    &::after {
        content: "";
        clear: both;
        display: block;
    }
}


app-order-reports-sidebar {
    .search-wrapper {
        padding: 16px;
        display: block;
        position: sticky;
        bottom: 0;
        background-color: #fff;
    }

    app-checkbox {
        padding: 16px;
        display: block;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background-color: #fff;
        border-top: 1px solid #ABBAD1;
        margin-top: -1px;
        border-bottom: 1px solid #efefef;
    }
}

.pageContent_wrapper.hasSidebar .sidebar_wrapper {
    display: block;
    position: relative;
    top: 0;
    overflow-y: auto;
    height: calc(100% - 80px);

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.35);
    }
}

app-abstract-dialog-container {
    .body_wrapper {
        min-width: 440px;
        min-height: 150px;
        padding-top: 16px;
    }
}

.canceled-details {
    .summary-data-wrapper  {
        p {
            color: $grey-alt;
        }
    }
    .summary-general-info-wrapper {
        h4 {
            color: $grey-alt;
        }
        th {
            color: $grey-alt;
        }
        td {
            color: $grey-alt;
        }
    }
    .dialog-section-header {
        color: $grey-alt;
    }
}

.edit-order-disabled {
    pointer-events: auto !important;
    cursor: auto !important;;
.summary-data-wrapper app-datetimepicker .date-picker-icons {
    position: relative !important;
    right: 20px !important;
  }
}
