




.dialog_title {
    label {
        line-height: 1;
        color: $grey-dark;
    }
}


.form_container {
    color: $black-dark;

    .header_wrapper {
        margin-bottom: $basis*2; //24px
        .header_container {
            padding: 0;
        }
    }

    .content_wrapper {
        position: relative;
    }

    .loginFormMessage_container {
        & label {
          color: red;
        }
      }

    .footer_wrapper {
        margin-top: $basis*3;
        position: sticky;
        background-color: #fff;
        z-index: 10;
        bottom: 0;
        padding:$basis 0 ;

        &.border-top {
            border-top: 1px solid $grey;
            margin-top: 0;
            padding: $lh 0;
        }
    }
    .formSection {
        margin: $basis*1.5 0;
    }

    .formRow {
        display: block;
        margin-bottom: $basis/1.5; //8px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .formCell {
        display: inline-block;
        padding-right: $lh;
        .button {
            width: $lh*7.5;
        }
        &:last-child {
            padding-right: 0;
        }
    }


    // Dropdown in a Form
    .dropdown_container {
        .dropdownList_container {
            max-height: 260px;
        }
    } // Dropdown :: end
}

// Tier style
.purchase-numeric-step-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .purchase-form-counts-price-input, .purchase-numeric-display-input-value {
        width: 80px;
        position: relative;
    }
    .purchase-form-counts-price-input {
        input {
            padding-left: 16px;
            width: 100%;

            &:focus {
                margin: inherit;
                padding-left: 16px;
            }
        }
    }
    .purchase-numeric-step-input-wrapper {
        input {
            width: 40px;
        }
    }

}


.component-input-image-upload{
    display: flex;
    input {
        width: calc(100% - 33px);
    }
    &.without-label{
        flex-basis: 100%;
        app-dropdown {
            width: 100%;
            .dropdown_container {
                max-width: 100%;
            }
        }
    }
}
