.page-wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    h1 {
        color: $black-dark;
    }

    h3 {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: $black-dark;
    }

    .page-content-header-wrapper {
        h1 {
            margin-bottom: 16px;
        }
    }

    .page-content {
        padding: 16px;
        //max-width: 1280px;
        margin: auto;
    }

    .page-content-sidebar {
        flex-basis: 100%;
        margin-right: 16px;
        min-height: initial;
        height: calc(100vh - 50px - 16px - 28px - 16px - 72px - 24px);
        overflow-y: auto;
        position: relative;

        h3 {
            margin-bottom: 16px;
        }

        ul:first-child {
            min-height: calc(100vh - 50px - 16px - 28px - 16px - 72px - 24px - 34px - 24px - 38px - 16px);
        }
    }

    .page-main-content-wrapper {
        flex-basis: 100%;
    }
}

.notificationAlert_wrapper {
    z-index: 200;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.warning,
    &.error {
        position: absolute;
        background-color: rgba(10, 10, 10, 0.2);
    }
}

// Header:
//  - header banner
//  - nav menu
.header_wrapper {
    z-index: 30;
    position: relative;

    .header_container {
        z-index: 30; // navigation: 10, header_content: 20
        position: relative;
        background-color: $black;
    }

    .navigation_wrapper {
        z-index: 10; //header: 20
        position: absolute;
        top: $header-height;
        left: 0;
        right: 0;
    }

    h1 {
        //font-weight: bold;
    }
}

// Page content:
// Full width page content (sidebar optional)
// Standard page layout (max-width: 1050)
.pageContent_wrapper {
    z-index: 10;
    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-y: auto;

    &.hasSidebar {
        .sidebar_wrapper {
            display: block;
        }

        div.mainContent_wrapper { //increasing specificity
            left: $sidebar-width;
        }
    }

    .sidebar_wrapper {
        display: none;
        box-sizing: content-box !important;
        position: absolute;
        top: 80px;
        left: 0;

        width: $sidebar-width;
        border-right: 1px solid $border;
        background-color: $white;

        .padding-row {
            padding: 0 round($basis * 1.33);
        }
    }


    .mainContent_wrapper { // Standard Page Layout
        margin: 0 auto;
        //padding-top: $basis*2; // breadcrumbs from header
        padding-bottom: $basis * 4; // bottom spacing
        max-width: $standard-page-width; // 1050px

        @media screen and (max-width: 1200px) {
            margin-left: $lh * 2;
            margin-right: $lh * 2;
        }

        .full-width-layout & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            margin: 0; //overwrite standard page layout
            padding-left: round($basis * 1.33);
            padding-right: round($basis * 1.33);
            max-width: 100%; //overwrite standard page layout
            min-height: 100%; // bug-fix: sidebar border full height
        }

        // Order Reports page
        .order-reports-page & { //full width layout with sidebar
            position: absolute;
            top: 78px;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-y: auto;
            margin: 0; //overwrite standard page layout
            max-width: 100%; //overwrite standard page layout
            min-height: calc(100% - 78px); // bug-fix: sidebar border full height

            border-left: 1px solid $border;
            background-color: $white;

            .row-padding {
                padding-left: $basis * 4;
                padding-right: $basis * 4;
            }

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                height: 7px;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.35);
            }
        }
    }

    .pageContent_container {
        position: relative;
        padding: 0 $lh * 1.5;

        .full-width-layout & {
            padding: 0;
            width: 100%;
            height: 100%;
        }
    }

    .infiniteScroll_container, .infiniteScroll__sale_group_customer_container {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }

    .infiniteScroll__sale_group_customer_container {
        max-height: 660px;
    }
}

.relative {
    position: relative;
}

.height-inherit {
    height: inherit;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.cursorPointer {
    cursor: pointer;
}

.fit_content_width {
    width: fit-content;
}

.cursorAuto {
    cursor: auto;
}

.displayNone {
    display: none;
}

.mat-element-bold {
    font-weight: bold;
}

.customers-standard-layout-wrapper {
    display: flex;
    flex-flow: column wrap;
}

.customers-standard-layout .page-wrapper .pageContent_wrapper app-customers-table {
    margin-right: 10px;
    margin-left: 10px;
}

.customers-standard-layout .pageTitle_container {
    margin-left: 10px;
}

.customers-standard-layout .mainContent_wrapper {
    padding: 0px !important;
}

.useTouchScreenLayout {
    app-step-1-buttons {
        position: absolute;
        margin-bottom: -15px;
    }
    app-step-2-buttons {
        position: absolute;
        margin-bottom: -44px;
    }
    .shopping-cart-footer-buttons-wrapper {
        .flex-container {
            .invisible-button {
                border: 1px solid #ffffff;
                margin-top: 15px;
            }
        }
        div {
            display: flex;
            flex-direction: column;
            width: 100%;

            button {
                height: 60px;
                font-size: 18px;
            }
        }
    }

    .app-shopping-cart-item-wrapper {
        height: 300px !important;
    }

    app-shopping-cart-content {
        p, div, h5 {
            font-size: 16px;
        }

        svg {
            height: 40px !important;
        }
    }

    .app-qsb-trip-type {
        h5, label {
            font-size: 16px;
        }

        app-radio-button-group {
            label {
                font-size: 16px !important;
                line-height: 25px !important;
                &:before {
                    top: 2px !important;
                    left: -10px !important;
                    width: 30px !important;
                    height: 30px !important;
                    margin-top: -4px !important;
                }
                &:after {
                    width: 15px !important;
                    height: 15px !important;
                    top: 5px !important;
                    left: -2px !important;
                }
            }
        }
    }

    app-tiers-selector-content {
        font-size: 16px;

        h5, label, input, p {
            font-size: 16px;
        }
        input:focus {
            font-size: 16px;
        }
        .empty-state {
            svg {
                height: 40px !important;
            }
        }
        .purchase-form-circle-button {
            height: 30px;
            width: 30px;
            border-radius: 15px;
        }
    }

    .app-qsb-order-info-content {
        label, input {
            font-size: 16px;
            margin-left: -2px;
        }
        h5, .checkboxLabelGroup {
            font-size: 16px;
            label {
                font-size: 16px !important;
                &:before {
                    top: 2px !important;
                    left: -2px !important;
                    width: 20px !important;
                    height: 20px !important;
                }
                &:after {
                    font-size: 12px !important;
                    top: 4px !important;
                    left: 1px !important;
                }
                padding-bottom: 10px;
            }
        }
    }

    app-order-info {
        > div {
            height: 380px !important;
        }
    }

    app-custom-fields {
        > div {
            height: 355px !important;
            margin-top: 110px !important;
        }
        svg {
            height: 40px !important;
        }
        h5, label, input, h4, p {
            font-size: 16px !important;
        }
    }

    app-payment-methods {
        font-size: 15px;
        h5, input, label {
            font-size: 16px;
        }
        input:focus {
            font-size: 16px;
        }
        app-radio-button-group {
            label {
                font-size: 16px !important;
                margin-bottom: 15px;
                line-height: 25px !important;
                padding-left: 32px !important;
                &:before {
                    left: -2px !important;
                    top: -2px !important;
                    width: 30px !important;
                    height: 30px !important;
                }
                &:after {
                    width: 15px !important;
                    height: 15px !important;
                    top: 5px !important;
                    left: 5px !important;
                }
            }
        }
    }

    .app-credit-card-detail {
        input, label, h5 {
            font-size: 16px;
        }
        .dropdownList_container {
            a {
                font-size: 15px !important;
            }
        }
    }

    app-order-info {
        label, h5, .checkboxLabelGroup {
            font-size: 16px;

            label {
                font-size: 16px !important;

                &:before {
                    top: 2px !important;
                    left: -2px !important;
                    width: 20px !important;
                    height: 20px !important;
                }

                &:after {
                    font-size: 12px !important;
                    top: 4px !important;
                    left: 1px !important;
                }

                padding-bottom: 10px;
            }
        }

        input {
            font-size: 16px !important;
        }

        .dropdownHeader_label {
            font-size: 16px;
        }

        li a {
            font-size: 16px !important;
        }
    }

    app-order-level-discounts-selector-content {
        h5, label, input {
            font-size: 16px !important;
        }
    }

    .order-input-wrapper {
        display: inline-block;
    }

    .discount-input-wrapper {
        display: flex;

        input[type="text"] {
            flex: 1;
        }
    }

    .purchase-form-circle-button {
        height: 30px;
        width: 30px;
        border-radius: 15px;
        margin-left: 8px;
    }

    .purchase-middle-section-button-wrappers {
        button {
            height: 60px !important;
            font-size: 18px;
        }
    }

    app-step-1-shopping-cart-item-selector {
        h5, label, input, .dropdownHeader_label {
            font-size: 16px !important;
        }

        li a {
            font-size: 16px !important;
        }

        app-radio-button-group {
            label {
                font-size: 16px !important;
                margin-bottom: 15px;
                line-height: 25px !important;
                padding-left: 32px !important;
                &:before {
                    left: -2px !important;
                    width: 30px !important;
                    height: 30px !important;
                }
                &:after {
                    width: 15px !important;
                    height: 15px !important;
                    top: 7px !important;
                    left: 6px !important;
                }
            }
        }
    }
}

.absolute {
    position: absolute;
}
