

.breadcrumbs {
    //margin-bottom: $basis*4;
    .breadcrumbsList_container {
        display: inline-block;

        .breadcrumbsLi {
            display : inline-block;
            cursor: default;
            &:after {
                display: inline-block;
                content: "/";
                top: 8px;
                right: 0;
                color: $white;
                font-size: $basis;
                opacity: 0.5;
                margin: 0 3px;

            }
            &:last-child {
                &:after {
                    content: '';
                }
                .breadcrumbsLink, span {
                    font-weight: bold;
                }
            }
            .breadcrumbsLink{
                display: inline-block;
                font-weight: 300;
                line-height: $basis*2; // 24px
                font-size: $basis; // 12px;
                color: $white;
                cursor: pointer;
                &:hover {
                    color: $blue-dark;
                }
            }

            span {
                display: inline-block;
                font-weight: 300;
                line-height: $basis*2; // 24px
                font-size: $basis; // 12px;
                color: $white;
                cursor: default;
            }
        }
    }
}
