



.notificationAlert_container {
    z-index: 201;
    position: absolute;
    top : $header-height*2;
    left: 50%;
    @include transform(translateX(-50%));

    display: inline-block;
	padding: $lh $lh*5 $lh $lh*3;
    max-width: $lh*20;

	background-color: $white;
	box-shadow: 0 3px 2px #cccccc80;

	border: 1px solid $grey-light;


    .warning &,
    .error & {
        border-color: $red;
        color: $red;
        .notificationAlertIcon_wrapper {
            background-color: $red;
            .error-icon {
                display: inline-block;
            }
        }
    }

    .success & {
        color: $green;
        border-color: $green;

        .notificationAlertIcon_wrapper {
            background-color: $green;
            .success-icon {
                display: inline-block;
            }
        }
    }

    .info & {
        //color: $blue;
        color: $black-dark;
        border-color: $blue;
        .notificationAlertIcon_wrapper {
            background-color: $blue;
            .info-icon {
                display: inline-block;
            }
        }
    }

    .notificationAlertIcon_wrapper {
        position: absolute;
        top   : 0;
        left  : 0;
        bottom: 0;

        width: $lh*2;
        font-size: $lh;
    }

	.notificationAlertIcon_container {
        display : none;
        position: absolute;
        top : 50%;
        left: 50%;
        @include transform (translate(-50%, -50%));

        color: white;
        font-size  : $lh;
        line-height: $lh;
		vertical-align: middle;
	}

    .notificationAlert_content {
        //padding-bottom: $lh;
        .notificationAlertDescription {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            orphans: 3;
            line-height: 1.2;
        }
    }

    .notificationAlertCloseButton_container {
		position: absolute;
		top: $lh/4;
		right: $lh/4;

        display: inline-block;
		padding: $lh/2;

		vertical-align: middle;
		color: $grey;

		.fa {
			font-size: $lh;
			line-height: $lh;
			vertical-align: middle;
		}
	}
}

.notificationAlertConfirmButton_container {
    position: absolute;
    bottom: 0;
    top: 0;
    height: 32px;
    width: 60px;
    margin: auto;
    background-color: rgba(217, 224, 234, 0.45);
    right: 10px;
    text-align: center;
    .notificationAlertConfirmButton_link {
        display: block;
        color: $black-dark;
        line-height: 32px;
	}
    &:hover {
        background-color: rgba(217, 224, 234, 0.65);
    }
}
/*****************/



.notification-alert-box {
    position: absolute;
    top : $lh*4;
    left: 0;
    right: 0;

    min-height: 104px;
    width: 429px;
    margin: auto;
    z-index: 9999;

    background-color: $white;
    color: $black-dark;
    box-shadow: 0 1px 4px 0 rgba(61,73,95,0.4);
    border: 1px solid;


    .notification-alert-box-icon {
        padding: $fs-large $fs-large/2;
        &:after, &::after {
            width: 20px;
            height: 20px;
            color: $white;
            display: block;
        }
        i {
            color: $white;
            font-size: 20px;
        }
        svg {
            width: 20px;
            height: 20px;
        }
        path {
            fill: $white;
        }
    }

    .notification-alert-box-content {
        display: flex;
        flex-direction: row;
        min-height: 102px;
    }

    .notification-alert-box-message {
        padding: $fs-large $fs-large $fs-small*2 $fs-small*2;
        h3 {
            text-transform: capitalize;
            font-weight: bold;
            font-size: $fs-xl;
            line-height: $fs-small*2;
        }

        p {
            font-size: $fs;
            line-height:  $fs-xl;
            min-height : $fs-xl*2 ;
            word-break: break-all;
        }

        i {
            color: $grey-dark;
        }
    }

    .close-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        path {
            fill: $black-light;
            &:hover {
                fill: $black-dark;
            }
        }
    }

    &.error {
        border-color: $notification-alert-error;
        .notification-alert-box-icon{
            background-color: $notification-alert-error;
        }
    }

    &.success {
        border-color: $notification-alert-success;
        .notification-alert-box-icon {
            background-color: $notification-alert-success;
        }
    }

    &.info {
        border-color: $notification-alert-info;
        .notification-alert-box-icon {
            background-color: $notification-alert-info;
        }
    }
}
























