
.no-permission_page  {
    .pageContent_container {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        width: 100%;
    }
    .emptyState_wrapper {        
        margin: 0 auto;
        text-align: center;
    }

}