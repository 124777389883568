
.datepicker_container {
    position: relative;
    display: flex;
    align-items: center;

    .datepicker-input {
        flex-basis: auto;
        min-width: 34px;
        width: 100%;
        max-width: 320px;
    }

    .date-picker-icons {
        position: absolute;
        right: 10px;
        color: $blue;
        opacity: 1;
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);

        .fa-clock {
            margin-left: 10px;
        }
    }
}

.datepicker-input-width .datepicker-input {
    max-width: 100% !important
}

.single-day-datepicker {
    max-width: $lh*16;
    @include display-flex;
    @include justify-content(space-between);

    .button {
        float: left;
        vertical-align: middle;

        width: $lh*3; //60px;
        // same height as input reduce for border
        padding: calc(#{$lh/4} - 1px) $lh/4;
    }

    .datepicker_container {
        float: left;
        width: unset;
        vertical-align: middle;
    }
}

.md-drppicker {

    .calendar-time {
        .select {
            .select-item {
                font-size: 16px !important;

                option {
                    font-size: 12px;
                }
            }
        }
    }

    table {
        border-collapse: unset;
    }

    .btn {
        width: 100px;
        height: 40px;
    }

    .buttons {
        clear: both;
    }

    &.double {
        @media print, screen and (min-width: 48em) {
            width: 530px !important;
        }
    }

    &.show-ranges {
        @media print, screen and (min-width: 48em) {
            width: 635px !important;
        }
    }

    .ranges {
        ul {
            li {
                button {
                    color: black;
                    font-size: 14px;
                }
            }
        }
    }
}

.summary-data-wrapper-departure .datepicker_container {
    min-width: 304px;
}

.summary-data-wrapper-custom input {
    max-width: 320px
}
