
.all-components-page {
    $color: #7a8aa3;

    section {
        margin-bottom: $basis*6;
    }
    table {
        width: 100%;
        td,th {
            height: $basis*4;
        }
        th {
            font-size: $basis*1.5;
            color: $color;
            font-weight: 300;
        }
    }

    .section-title {
        font-weight: 300;
        color: $color;
        text-transform: uppercase;
        font-size: $basis*2;
    }

    .section-text {
        font-weight: 300;
        color: $color;
        font-size: $fs;
    }

    .inline-list {
        .inline-li {
            display: inline-block;
            padding-right: $basis;
            &:last-child {
                padding-right: 0;
            }
        }
    }


    .align-top {
        vertical-align: top;
    }

    .standard-datepicker .datepicker_container{
        max-width : 320px;
    }

}

.filterTag {
    display: block;

    line-height: $lh;
    font-size: $fs;
    color    : $black-dark;
    &:hover {
        color: $blue-dark;
        &:before {
            color: $blue-dark;
        }
    }
    .filterTagIcon {
        display: inline-block;
        height: $fs;
        width: $fs;
        color: $blue-dark;
        margin-right: 3px;
    }
}
