.white-text {
    color: $white;
}
.dark-grey-text {
    color: $grey-dark;
}
.grey-text {
    color: $grey;
}
.text-align-right {
    text-align: right;
}
.italic-text {
    font-style: italic;
}
.primary-text {
    color: $primary-color;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}
