
.mainContent_container {
    .mainContentHeader_wrapper {
        position: relative;
        z-index: 20;
    }

    .ctaButton_wrapper {
        margin-top: $lh*2;

        &.genrateReport {
            max-width: $lh*10;
        }
    }

    .orderReportTable_wrapper {
       // padding: $lh/2 0;
    }
}

app-order-reports {
    .mainContentHeader_wrapper {
        border-bottom: 1px solid #ddd;
        padding: 0 $basis*1.33;
    }
}

.order-reports-page {

    .selectedFiltersArea_wrapper {
        position: relative;
        z-index: 19;
        min-height: 1px;
        white-space: nowrap;

        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        background-color: $selected-filters-area;

        &.disabled,
        &:disabled {
            opacity: 0.45;
            pointer-events: none;
        }

        &.active {
            border-color: $blue;
            background-color: rgba($blue, 0.35);
        }

        .fixedButton {
            position: absolute;
            top: -1px;

            //To stand on half border selectedFiltersArea_wrapper
            /*left: $basis*4;
            @include transform(translateY(-50%));*/
        }

        .emptyState {
            padding: ($basis*3.5 - 2) 0; // reduced for border
            color: $black-dark;
        }

        .dropArea.is-active {
            padding: $basis*1.5 0;
            width: $basis *20;
            text-align: center;
        }
    }

    .selectedFieldsArea_wrapper {
        position: relative;

        .emptyState {
            color: $black-dark;
        }

        .selectedFieldsArea {
            background-color: $selected-fields-area;
            padding: $basis*2 0;
            white-space: nowrap;
            overflow-x: auto;

            &.disabled,
            &:disabled {
                opacity: 0.45;
                pointer-events: none;
            }

            &.active {
                border-color: $blue;
                background-color: rgba($blue, 0.35);
            }
        }

        .fieldsInfoMessageArea {
            background-color: $fields-info-message;
            padding: $basis/2 0;
            color: $white;
            font-weight: 600;
            text-align: center;
        }
    }

    .buttons_wrapper {
        margin: $basis*1.08 $basis*2;
    }
}





