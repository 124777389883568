.customer-owners-page-wrapper {
    position: initial;
    overflow: initial;
    .page-content-body-wrapper {
        display: flex;
        align-items: flex-start;
    }



    app-customer-owners-permissions {
        display: block;
        height: calc(100vh - 50px - 16px - 28px - 16px - 72px - 24px - 34px);
        overflow-y: auto;
        width: calc(100% + 16px);

        .no-permission-wrapper {
            padding-top: 74px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            svg {
                margin-bottom: 20px;
            }
            h2 {
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: $black-dark;
                margin-bottom: 6px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: rgba(21, 25, 35, 0.45);
            }
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.35);
        }
    }
    .customer-permission-tree-wrapper {
        padding-top: 9px;
    }
    .empty-state-wrapper {
        margin: auto;
        align-items: center;
        display: flex;
        width: 400px;
        flex-direction: column;
        div {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }
        .icon_button {
            align-items: center;
            display: flex;
        }
        .no-customers-icon {
            margin-bottom: 20px;
        }
        h1, p {
            margin-bottom: 8px;
        }
        span {
            margin-left: 8px;
        }
    }

    .remove {
        display: none!important;
    }

    .mat-checkbox {
        margin-left: -10px !important;
    }

    .checklist-leaf-node label {
        margin-left: -4.5rem;
        padding: 5px
      }

    .mat-tree-node-def button{
          padding-bottom: 0px;
          padding-top: 0px;
      }

    .mat-tree-child-node {
        padding-right: 0px;
        z-index: 2;
        border: none;
        width: 0px;
        margin-left: -4rem;
        &:hover {
            color: #08cbbf !important;
            background-color: transparent !important;
        }
        &:focus {
            color: #08cbbf !important;
            background-color: transparent !important;
        }
        mat-icon{
            padding-right: 5px;
        }
    }

    .mat-tree-child-node-href {
        &:hover {
            text-decoration: underline;
        }
    }

    .fas {
        margin-top:9px;
        margin-bottom:9px;
        position: relative;
        background: white;
        margin-left: 5px;
        font-style: normal;
        font-size: 15px;
        font-weight: 600;
    }

    .mat-tree-node-child-element {
        color:black;
        margin-bottom: -30px;
    }

    .mat-tree-node-child-element-inactive {
        color:rgba(21,25,35,.5);
        margin-bottom: -30px;
    }

    .mat-tree-node-child-add {
        color:#40b1d1;
        margin-bottom: -30px;
    }

    .mat-tree-node-parent-element {
        color:black;
        margin-top: -1px;
        margin-left: 40px;
    }

    .mat-tree-node-parent-element-inactive {
        color:rgba(21,25,35,.5);
        margin-top: -1px;
        margin-left: 40px;
    }

    .mat-tree-node-parent-add {
        color:#40b1d1;
        margin-top: 18px;
        margin-bottom: 50px;
        margin-left: 40px;
    }

    .mat-checkbox-layout {
        margin-left: 40px;
    }

    li {
        margin-left: -29px;
        border: 1px solid  #d0dcef;
        border-width: 0 0 1px 1px;
        padding-bottom: 28px;
      }

    .mat-tree-node {
      background-color: white;
      padding-left: 10px;
      min-height: 0px;
    }
    li.mat-tree-node,
    li div {
      position: relative;
      top: 12px;
      padding-left: 10px !important;
    }
    .mat-tree-node-display{
        margin: 4px;
        height: 10px;
        background: transparent;
    }

    .mat-checkbox-input {
        width: 15px !important;
        margin-top: 1px;
        z-index: 2;
        position: absolute;
        height: 15px;
    }

    .mat-checkbox-frame {
        display: none;
    }

    .mat-checkbox-inner-container{
        width: 15px;
        border: 1px solid #546179;
        border-radius: 3px;
    }

    .mat-checkbox-ripple .mat-ripple-element, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #40b1d1;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-inner-container  {
        border: none;
    }

    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-inner-container {
        border: none;
    }

    .mat-tree-node button {
        border: none;
        width: 0px;
    }

    input[type="checkbox"]:focus{
      appearance: auto;
    }

    .tree-loader .loader_wrapper{
        background-color: rgba(255, 255, 255, 1) !important;
    }

}

.customer-owners-action-buttons-wrapper {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0 ;
    margin-top: 8px;
    background: $white;
    button {
        width: 136px;
        font-size: 16px;
        padding: 9px 0;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}

app-yes-no-dialog {
    .reveal {
        min-width: 619px;
        min-height: 249px;
        padding: 23px 26px;
        p {
            font-size: 16px;
        }
    }
    .content_wrapper {
        min-height: 105px;
    }
    .form_container .footer_wrapper {
        margin-top:0;
        padding: 0;
        justify-content: center;
    }
    .users-and-permissions-action-buttons-wrapper {
        padding: 0;
    }
}
