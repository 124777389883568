app-passes {
    app-pass-content {
        display: block;
        height: 100%;
        width: 100%;
    }

    .min-width-150 {
        width: 150px;
    }
    .full-width-layout {
        .pageContent_wrapper {
            max-width: initial;
            margin: auto;
            position: initial;
            top: initial;
            left: initial;
            right: initial;
            bottom: initial;
            .mainContent_wrapper {
                position: initial;
                top: initial;
                left: initial;
                right: initial;
                bottom: initial;
            }
        }
    }

    .mat-tree-child-node {
        border: none;
        width: 0px;
        margin-left: -4rem;
        &:hover {
            color: #08cbbf !important;
            background-color: transparent !important;
        }
        &:focus {
            color: #08cbbf !important;
            background-color: transparent !important;
        }
    }

    .mat-tree-child-node-href {
        &:hover {
            text-decoration: underline;
        }
    }

    .fas {
        margin-top:9px;
        margin-bottom:9px;
        position: relative;
        background: white;
        margin-left: 5px;
        font-style: normal;
        font-size: 15px;
        font-weight: 600;
    }

    .mat-tree-node-child-element {
        color:black;
        margin-bottom: -30px;
    }

    .mat-tree-node-child-element-inactive {
        color:rgba(21,25,35,.5);
        margin-bottom: -30px;
    }

    .mat-tree-node-child-add {
        color:#40b1d1;
        margin-bottom: -30px;
    }

    .mat-tree-node-parent-element {
        color:black;
        margin-top: -1px;
    }

    .mat-tree-node-parent-element-inactive {
        color:rgba(21,25,35,.5);
        margin-top: -1px;
    }

    .mat-tree-node-parent-add {
        color:#40b1d1;
        margin-top: 18px;
        margin-bottom: 50px;
    }

    .node-list-item {
        margin-left: -29px;
        border: 1px solid  #d0dcef;
        border-width: 0 0 1px 1px;
        padding-bottom: 28px;
      }

    .mat-tree-node {
      background-color: white;
      padding-left: 10px;
      min-height: 0px;
    }
    .tree-loader .loader_wrapper{
        background-color: rgba(255, 255, 255, 1) !important;
    }
    .node-list-item.mat-tree-node,
    .node-list-item div {
      position: relative;
      top: 12px;
      padding-left: 10px !important;
    }
    .mat-tree-node-display{
        margin: 4px;
        height: 10px;
        background: transparent;
    }

    .pass-wrapper {
        position: relative;
        .page-content-sidebar {
            height: calc(100vh - 143px);
            min-width: 320px;

            ul:first-child {
                min-height: calc(100vh - 247px);
            }
        }

        .pass-content {
            height: 100%;
        }

        .pass-details-header {
            .icon_container {
                position: absolute;
                top: 0;
                right: 0;
                color: $grey-dark;
                cursor: pointer;
            }
        }

        .empty-state-wrapper {
            padding-top: 90px;
            margin: auto;
            align-items: center;
            display: flex;
            flex-basis: 100%;
            flex-direction: column;
            height: inherit;

            .icon_button {
                align-items: center;
                display: flex;
            }

            .no-passes-icon {
                margin-bottom: 20px;
            }

            h2 {
                color: #7B88A3;
            }

            p {
                color: #BDC3D1;
            }

            span {
                margin-left: 8px;
            }
        }

        .tab-content {
            min-height: calc(100% - 45px);
            width: calc(100vw - 453px);
            max-height: calc(100vh - 188px);
        }

        .pass-details {
            display: flex;
            align-items: center;
            label {
                flex-basis: 278px;
            }
            svg.inactive {
                path {
                    fill: $grey;
                }
            }

        }

        .deactiveWarning {
            color:red;
        }

        .pass-image {
            flex-basis: 278px;

            app-image-upload {
                display: block;
                width: 100%;
                input[type='text'] {
                    flex-basis: 278px;
                }
            }

            .filled-state {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 100%;
                    max-height: 259px;
                    display: block;
                    margin: auto;
                }
            }
        }

        .pass-options-wrapper {
            flex-basis: 262px;
        }

        .filter-action-btn {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                color: #40B1D1;
            }
        }

        .icon_button {
            padding: 0;
        }
    }

    .pass-products-wrapper, .pass-costs-wrapper {
        max-height: 100px;
    }

    .table-actions-dropdown-wrapper {
        position: absolute;
        top: 24px;
        z-index: 10;
        left: 0;
        background-color: $white;
        width: 205px;
        box-shadow: 0 3px 5px rgba(84, 97, 121, 0.3);
        border: 1px solid #D0DCEF
    }

    .table-action {
        cursor: pointer;
        height: 32px;
        padding: 0 16px;
        line-height: 32px;

        &:hover {
            background: rgba(204, 204, 204, 0.2);
            font-weight: 600;
        }
    }

    .pricings-management-header-actions {
        align-items: end;
        justify-content: space-between;

        .filter-tags-wrapper {
            color: $black-dark;
            flex-wrap: wrap;
        }

        .filter-tag {
            background-color: #e6e6e6;
            padding: 2px 12px;
            border-radius: 24px;
            cursor: pointer;
            line-height: initial;
            transition: 0.1s background-color, 0.1s color;

            &:hover, &.selected {
                background-color: #40B1D1;
                color: $white;
            }
        }
    }

    .filter-pricings-sidebar {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 290px;
        top: 50px;
        height: calc(100vh - 50px);
        background-color: #D0DCEF;
        z-index: 3;
        transition: transform ease 0.4s;
        will-change: transform;

        .close-icon {
            align-self: flex-end;
            cursor: pointer;

            path {
                fill: #546179;
            }

            &:hover {
                path {
                    fill: #000000;
                }
            }
        }
        .md-drppicker {
            position: fixed !important;
        }
    }

    .filter-action-buttons-wrapper {
        .button {
            font-size: 14px;
            height: 36px;
            line-height: 100%;
            min-width: calc(50% - 8px);
            &.ghost-button {
                margin-right: 8px;
            }
        }
    }

    .filter-option {
        .label {
            margin-bottom: 4px;
        }
    }

    .filter-wrapper {
        height: calc(100% - 12px - 32px - 16px);
    }

    .filter-pricings-btn {
        cursor: pointer;

        svg path {
            fill: #3D495F;
        }

        span {
            color: #3D495F;
        }

        &:hover, &.selected {
            svg path {
                fill: #40B1D1;
            }

            span {
                color: #40B1D1;
            }
        }
    }

    .show-table-actions {
        cursor: pointer;

        &.selected {
            svg circle {
                fill: #40B1D1;
            }
        }
    }

    .table-of-pricings {
        max-height: calc(100vh - 50px - 70px - 64px - 16px - 135px);
        overflow-y: auto;
        display: block;
        @extend .overflow;

        .table_container {
            margin-bottom: 0;
        }

        .table_stripped, thead {
            border-top: 0;
        }

        th {
            position: -webkit-sticky;
            position: sticky;
            top: -1px;
            background-color: #798aa3;
            z-index: 2;
        }
    }

    .pass-tiers-tooltip-wrapper {
        // position: absolute;
        position: fixed;
        width: 390px;
        background-color: $white;
        padding: 16px;
        top: 38px;
        right: 138px;
        z-index: 10;
        box-shadow: 0 0 6px 1px #d8d8d8;

        p {
            min-height: 32px;
        }

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            display: block;
            right: -16px;
            border-right: transparent solid 8px;
            border-bottom: transparent solid 8px;
            border-top: transparent solid 8px;
            border-left: white solid 8px;
            -webkit-filter: drop-shadow(0 3px 4px #d8d8d8);
            filter: drop-shadow(4px 0px 3px #d8d8d8);
            z-index: 0;
        }

        &.arrow-top {
            &:after {
                top: 8px;
            }
        }

        &.arrow-bottom {
            &:after {
                bottom: 8px;
            }
        }
    }

    .pass-tiers-tooltip-header {
        position: relative;

        p {
            font-weight: bold;
        }
    }

    .pass-tiers-tooltip-row, .pass-tiers-tooltip-header {
        p:nth-child(1) {
            width: 50%;
        }

        p:nth-child(2) {
            width: 50%;
        }
    }

    .pricings-action-btn {
        cursor: pointer;
    }

    .animate-out {
        transform: translateX(100%);
    }

    .animate-in {
        transform: translateX(0);
    }

    .focused {
        .dropdownHeader_wrapper, .datepicker-input {
            border: 1px solid $blue;
        }
    }

    .cta-buttons-wrapper {
        position: relative;
        margin-top: 8rem;
        .button {
            margin-right: 16px;
            width: 170px;
            padding: 9px 22px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

app-abstract-dialog-container {
    .pass-dialog-content {
        width: 572px;

        app-dropdown {
            display: block;
            height: 32px;
            margin-top: 4px;
        }

        .order-form-item {
            width: 100%;
            align-items: flex-start;
        }

        .order-label {
            padding-top: 5px;
            flex-basis: 286px;
            margin-right: 13px;
        }

        .order-input-wrapper {
            flex-basis: 273px;
        }

        .body_wrapper {
            min-height: 95px;
            padding-top: 10px;
        }

        .edit-dialog-tier-wrapper {
            h3 {
                margin-bottom: 8px;
            }

            .edit-dialog-tier-header {
                @include display-flex;
                @include align-items(center);
                @include justify-content(space-between);
                min-height: 30px;
                margin-bottom: 8px;

                .edit-dialog-tier-name-header {
                    flex-basis: 286px;
                    margin-right: 13px;
                }

                .edit-dialog-tier-cost-header {
                    @include display-flex;
                    @include align-items(center);
                    @include justify-content(space-between);
                    flex-basis: 273px;

                    *:nth-child(1) {
                        width: calc(50% - 4px);
                        padding-right: 4px;
                    }

                    *:nth-child(2) {
                        width: calc(50% - 4px);
                        padding-left: 4px;
                    }
                }
            }

            .edit-dialog-tier-row-container {
                max-height: 300px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: rgba(0,0,0,.5);
                    -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.35);
                }

                .edit-dialog-tier-row {
                    min-height: 30px;
                    margin-bottom: 8px;

                    app-edit-pass-tier-dialog-form {
                        width: 100%;
                        form {
                            width: 100%;
                            @include display-flex;
                            @include align-items(center);
                            @include justify-content(space-between);

                            .edit-pass-tier-label {
                                flex-basis: 286px;
                                margin-right: 13px;
                            }

                            .edit-pass-tier-input-wrapper {
                                @include display-flex;
                                @include align-items(center);
                                @include justify-content(space-between);
                                flex-basis: 273px;

                                *:nth-child(1) {
                                    width: calc(50% - 4px);
                                    padding-right: 4px;
                                }

                                *:nth-child(2) {
                                    width: calc(50% - 4px);
                                    padding-left: 4px;
                                }

                                &.edit-pass-tier-full-width-input-wrapper {
                                    input {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .inputLabelGroup {
            .label {
                margin-bottom: 4px;
            }
        }

        .dialog-inner-wrapper {
            background-color: $white;
        }

        .dialog-action-buttons-wrapper {
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: 0;
            margin-top: 8px;
            background: $white;

            button {
                min-width: 160px;
                font-size: 16px;
                padding: 9px 0;
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
