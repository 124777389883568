.button, button {
	display: inline-block;
	margin : 0;
	padding: calc(#{$lh/2} - 1px) $lh*2; //40px  60px
	border : 1px solid $green;
	@include border-radius (0);

    width: $button-width; //150px
	vertical-align: middle;
    line-height: $lh;
    font-family: $ff_sans-pro;
    font-size: $fs-large;

    -webkit-appearance: none;
    transition: none;
    text-align: center;
    cursor: pointer;

    background-color: $white;
    color: $green;
    white-space: nowrap;


    &:hover, &:focus {
    	background-color: $green-dark;
		color  : $white;
		outline: none;
    }

    &:disabled,
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    	&:hover {
			cursor: not-allowed;
    		opacity: 0.4
    	}
    }
    &.extend,
    &.expanded{
    	display: inline-block;
    	width: 100%;
    	max-width: $lh*30;
    	margin-right: 0;
    	margin-left: 0;
    }
    &.large {
        font-size: $fs-large;
        width: 200px;
    }
    &.small {
        font-size: $fs-small;
        line-height: 1;
        padding: ($basis/2 - 1) 0;
        width: $basis*8; // 96px
    }
}

.costPrimaryButton {
    display: none;
}

.emptyStateButton,

.emptyBtn {
	@extend .button;
}

.button {
    &.primaryButton,
    &.primaryBtn,
    &.primary {
        background-color: $green;
        color: $white;

        &:hover {
            background-color: $green-dark;
            border-color: $green-dark;
            color: $white;
        }
        &.disabled {
            background-color: $green;
            border-color: $green;
            color: $white;
        }
    }
    &.danger {
        border-color:#E45B75;
        background-color: #E45B75;
        color:#ffffff;
        &:hover {
            background-color: #a35252;
        }
    }
}

.button-admin-tool {
    background-color: $blue;
    color: $white;

    &:hover {
        background-color: $blue-dark;
        border-color: $blue-dark;
        color: $white;
    }
    &.disabled {
        background-color: $blue;
        border-color: $blue;
        color: $white;
    }

    &:focus {
        background-color: $blue-dark;
        border-color: $blue-dark;
    }
}

.button {
    &.secondaryButton,
    &.secondaryBtn,
    &.secondary {
        background-color: $white;
        border: 1px solid $grey-dark ;
        color: $black-dark ;
        &:hover {
            background-color: $grey ;
            border: 1px solid $grey;
            color: $black-dark;
        }
        &:disabled,
        &.disabled {
            opacity: 0.4;
            &:hover {
                background-color: $grey-light;
                color: $grey-dark;
            }
        }
    }
}

.buttonsGroup_container {
    .buttonsGroup_item {
        display: inline-block;
    }
}

.invisible-button {
    background-color: transparent;
    border: medium none;
    color: #546179;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        border: inherit;
        color: inherit;
    }
    &:active, &:focus {
        background-color: transparent;
        border: medium none;
        color: #546179;
    }
}

.ghost-button {
    border: 1px solid #546179;
    background-color: $white;
    color: #546179;
    min-width: 150px;
    width: initial;
    &:hover, &:active, &:focus {
        background-color: $white;
        border: 1px solid #546179;
        color: inherit;
    }
}

.button-order-history {
    padding: 0 6px 0 6px;
    height: 34px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 8px;
        width: 14px;
    }
}

.button-solid-danger {
    border-color:#E45B75;
    background-color: #E45B75 !important;
    color:$white;
    &:hover {
        background-color: #d05a68;
    }
}

.button-ghost-danger {
    border-color:#E45B75;
    background-color: $white;
    color: #E45B75;
    width: initial;
    padding: 9px 22px;
    &:hover {
        background-color: #d05a68;
        color: $white;
    }
    &:focus {
        background-color: #d05a68;
        color: $white;
        border-color: $red-dark;
    }
}

.button.small-6, button.small-6 {
    width: 50%;
}

.button.small-12, button.small-12 {
    width: 100%;
}

.purchase-form-circle-button {
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 100%;
    background-color: #3fc0bc;
    border-radius: 50%;
    padding: 3px;

    svg {
        path {
            fill: #fff;
        }
    }
    &:disabled {
        svg {
            path {
                fill: #D0DCEF;
            }
        }
    }
}

.button-ghost-primary {
    border-color: $green;
    background-color: $white;
    color: $green;
    width: initial;
    padding: 9px 22px;
    &:hover {
        background-color: $green;
        color: $white;
    }
}
