/* Order by priority */
/* Important to maintain */

// Open Sans Pro
@import 'font-mixins';

// Font, Color, Width & Height
@import "variables/register";

// Foundation Grid, XY-Grid
@import "grid/register";

// Main Layout, Main Style, Standard Page Style
@import "global/register";

// Overwritten Third Party Styles
@import 'third-party-styles/register';

// Elements: input, button, checkbox, label, ...
@import "elements/register";

// Components: dialog, header, table, accordion, breadcrumbs, loader,...
@import "components/register";

// Modules: login, management, reports, purchase
// e.g. Style specific for a page
@import "modules/register";

//Print layouts
@import "print/main";
